app-select {
  .ng-select {
    $self: &;
    min-height: $input-height;

    &#{ $self }-sm {
      min-height: $input-height-sm;
      font-size: inherit;
    }
    &#{ $self }-lg {
      min-height: $input-height-lg;
    }

    &-disabled {
      .ng-arrow-wrapper {
        visibility: hidden;
        width: 1em !important;
        padding: 0;
      }
    }

    &.ng-select-opened {
      > .ng-select-container {
        .ng-arrow {
          top: 0;
          border: none;
          transform: rotate(180deg);
        }
      }
    }

    .ng-select-container {
      cursor: pointer;
      border: 1px solid $ng-select-border;
      &:hover {
        box-shadow: none;
      }
      .ng-value-container {
        .ng-placeholder {
          font-weight: lighter;
        }
      }
    }

    .ng-arrow-wrapper {
      width: auto;
      @extend .px-2;

      .ng-arrow {
        border: none;
        background: url(data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=)
          no-repeat;
        height: 24px;
        width: 24px;
        display: block;
        flex-shrink: 0;
        margin-left: auto;
        position: relative;
        transition: transform 250ms ease-in-out;
      }
    }

    app-form-control {
      margin-bottom: 0;

      .input-group__text {
        min-height: 32px;
      }
    }
  }

  .ng-dropdown-panel {
    background-color: $ng-select-dropdown-bg;
    border: 1px solid $ng-select-dropdown-border;
    box-shadow: $ng-select-box-shadow;
    margin: -16px;
    padding: 15px 7px 15px 15px;
    width: calc(100% + 32px);
    display: block;
    border-radius: $ng-select-border-radius;

    &.ng-select-top {
      bottom: -8px;
      border-top-right-radius: $ng-select-border-radius;
      border-top-left-radius: $ng-select-border-radius;
      border-bottom-color: $ng-select-border;
      margin-bottom: 0;
      .ng-dropdown-panel-items {
        .ng-option {
          &:first-child {
            border-top-right-radius: $ng-select-border-radius;
            border-top-left-radius: $ng-select-border-radius;
          }
        }
      }
    }
    &.ng-select-right {
      left: 0;
      top: 0;
      border-top-right-radius: $ng-select-border-radius;
      border-bottom-right-radius: $ng-select-border-radius;
      border-bottom-left-radius: $ng-select-border-radius;
      border-bottom-color: $ng-select-border;
      margin-bottom: 0;
      .ng-dropdown-panel-items {
        .ng-option {
          &:first-child {
            border-top-right-radius: $ng-select-border-radius;
          }
        }
      }
    }
    &.ng-select-bottom {
      top: -8px;
      border-bottom-right-radius: $ng-select-border-radius;
      border-bottom-left-radius: $ng-select-border-radius;
      border-top-color: $ng-select-border;
      margin-top: 0;
      .ng-dropdown-panel-items {
        .ng-option {
          &:last-child {
            border-bottom-right-radius: $ng-select-border-radius;
            border-bottom-left-radius: $ng-select-border-radius;
          }
        }
      }
    }
    &.ng-select-left {
      left: 0;
      top: 0;
      border-top-left-radius: $ng-select-border-radius;
      border-bottom-right-radius: $ng-select-border-radius;
      border-bottom-left-radius: $ng-select-border-radius;
      border-bottom-color: $ng-select-border;
      margin-bottom: 0;
      .ng-dropdown-panel-items {
        .ng-option {
          &:first-child {
            border-top-left-radius: $ng-select-border-radius;
          }
        }
      }
    }
    .ng-dropdown-header {
      border-bottom: 1px solid $ng-select-border;
      padding: 5px 7px;
    }
    .ng-dropdown-footer {
      border-top: 1px solid $ng-select-border;
      padding: 5px 7px;
    }
    .ng-dropdown-panel-items {
      &.scroll-host {
        padding-right: 10px;
        scrollbar-color: $primary $gray-400;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 1em;
          background: $gray-400;
          border-left: 2px solid $ng-select-bg;
          border-right: 2px solid $ng-select-bg;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 1em;
          border-left: 1px solid $ng-select-bg;
          border-right: 1px solid $ng-select-bg;
          background: $primary;
          &:hover {
            border-radius: 1em;
            border-left: 1px solid $primary;
            border-right: 1px solid $primary;
          }
        }
      }
      .ng-optgroup {
        user-select: none;
        padding: 8px 10px;
        font-weight: 500;
        color: $ng-select-dropdown-optgroup-text;
        cursor: pointer;
        &.ng-option-disabled {
          cursor: default;
        }
        &.ng-option-marked {
          background-color: $ng-select-marked;
        }
        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          color: $ng-select-dropdown-optgroup-marked;
          background-color: $ng-select-selected;
          font-weight: normal;
        }
      }

      .ng-option {
        border-radius: $ng-select-border-radius;
        padding: 10px 8px 10px 16px;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          padding: 10px 40px 10px 16px;
          position: relative;
          .ng-option-label {
            font-weight: normal;
          }

          &::after {
            background: url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZyBpZD0icGtvX2ljb25fc3RhdHVzLWFrY2VwdF8yNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOCAtMjkwMCkiPgogICAgPHJlY3QKICAgICAgaWQ9IlJlY3RhbmdsZV8zOTgzIgogICAgICBkYXRhLW5hbWU9IlJlY3RhbmdsZSAzOTgzIgogICAgICB3aWR0aD0iMjQiCiAgICAgIGhlaWdodD0iMjQiCiAgICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExOCAyOTAwKSIKICAgICAgZmlsbD0iI2ZmZiIKICAgICAgb3BhY2l0eT0iMCIKICAgIC8+CiAgICA8cGF0aAogICAgICBpZD0idl82XyIKICAgICAgZD0iTTEwLjgsMTUuNCwyNiwxLjJhMy42MDYsMy42MDYsMCwwLDAtMi0uNmMtLjcsMC0xLjcuMi0zLjMsMS45aDBsLTguOCw4LjItLjguOC0uOC0uOC0zLTMuMWMtMS42LTEuNy0yLjUtMi0zLjMtMmEzLjQ1NiwzLjQ1NiwwLDAsMC0yLC43WiIKICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTE2IDI5MDMuOSkiCiAgICAgIGZpbGw9IiMyZTdkNDkiCiAgICAvPgogIDwvZz4KPC9zdmc+Cg==)
              no-repeat;
            bottom: 0;
            content: "";
            height: 24px;
            margin: auto;
            position: absolute;
            right: 1px;
            top: 0;
            width: 28px;
          }
        }
      }
    }
  }

  .account {
    &__title {
      @extend %text-md;
      display: flex;
    }
    &__name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }
    &__balance {
      font-weight: 300;
    }

    &__number {
      @extend %text-sm;
      color: $text-muted;
    }
  }
}

.form-summary app-select {
  .ng-select-container {
    background-color: transparent !important;
    border: none;
    text-align: center;
    .ng-value-container {
      justify-content: center;
    }
  }
}

app-select-account,
app-select-default-account {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ng-select {
    &.ng-select-single {
      .ng-select-container {
        height: 64px;
      }
    }
  }
}
