// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

// Here you can override bootstrap variables from /bootstrap/scss/_variables.scss
// https://getbootstrap.com/docs/5.0/customize/overview/

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$font-family: pkobp, Tahoma, sans-serif;
$font-size-base: 0.9375rem; // 15px - assuming browser font size is 16px

// Bootstrap colors
$gray-100: #f9f9f9;
$gray-200: #f2f2f2;
$gray-300: #e5e5e5;
$gray-400: #d5d5d5;
$gray-500: #939393;
$gray-600: #818181;
$gray-700: #636363;
$gray-a100: #fafafa;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "a100": $gray-a100,
);

$primary: #003574;
$primary-light: #004c9a;
$primary-dark: #001e4b;
$secondary: #fff;
$success: #2e7d49;
$info: #939393;
$warning: #f5ab10;
$danger: #ca171d;
$light: #f9f9f9;
$dark: #000;
$white: #fff;

$text-color: $dark;
$text-muted: $gray-700;

$input-border-color: #b7b7b7;
$input-box-shadow: none;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-focus-width: 0;

$input-padding-y: 9px;
$input-padding-x: 14px;
$input-font-size: 15px;
$input-line-height: 1.333333333333333;

$input-padding-y-sm: 5px;
$input-padding-x-sm: $input-padding-x;

$input-padding-y-lg: 13px;
$input-padding-x-lg: $input-padding-x;
$input-disabled-border-color: $gray-400;

$input-height: 40px;
$input-height-sm: 32px;
$input-height-lg: 48px;

$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='-12 -12 24 24'><circle r='11' fill='#fff'/></svg>");
$form-switch-focus-bg-image: $form-switch-bg-image;
$form-switch-checked-bg-image: $form-switch-bg-image;
$form-switch-focus-color: #b7b7b7;
$form-switch-color: $gray-500;
$form-switch-checked-color: $primary;
$form-switch-padding-start: 36px;

$form-check-input-border: 1px solid rgba(0, 0, 0, 0.5);
$form-check-input-focus-box-shadow: none;
$form-check-radio-checked-color: $primary;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-radio-checked-color}'/></svg>");

$btn-focus-box-shadow: none;
$btn-focus-width: 0;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$custom-colors: (
  "cta-orange": #db912c,
  "cta-red": #e4202c,
  "body-light": $gray-200,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

// Options
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: false !default;
$enable-cssgrid: true !default;
$enable-validation-icons: false;

$ng-select-border: $input-border-color;
$ng-select-box-shadow: $input-focus-box-shadow;
$ng-select-height: 40px;
$ng-select-highlight: $primary;
$ng-select-marked: $gray-100;
$ng-select-placeholder: $gray-700;
$ng-select-primary-text: $text-color;
$ng-select-selected: $gray-200;
$ng-select-value-font-size: inherit;
$ng-select-value-padding-left: 16px;

$currency-pair-separator-color: #b7b7b7;

$heights: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
);

$utilities: (
  "border-style": (
    property: border-style,
    values: dashed,
  ),
  "height": (
    property: height,
    responsive: true,
    class: h,
    values: $heights,
  ),
  "overflow-y": (
    property: overflow-y,
    values: auto hidden visible scroll,
  ),
  "gray-color": (
    property: background-color,
    class: gbg,
    values: $grays,
  ),
  "gray-text": (
    property: color,
    class: gtext,
    values: $grays,
  ),
);

// Grid settings
$grid-gutter-width: 30px;
$grid-gutter-width-xs: 16px;

$grid-breakpoints: (
  xxs: 0,
  xs: 320px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
);

$container-max-widths: (
  sm: 702px,
  md: 990px,
  lg: 1230px,
);

// Progress bars
$progress-height: 5px;

$modal-sm: auto;
$modal-md: auto;
$modal-lg: auto;
$modal-xl: auto;

$shortcut-width: 78px;

$zindex-preloader: 1035;
$zindex-preloader-indicator: 1036;
$zindex-preloader-modal: 1085;
$zindex-preloader-indicator-modal: 1086;
$preloader-bg-transparent: rgba(255, 255, 255, 0.5);

$tooltip-max-width: 264px;
$tooltip-color: $text-color;
$tooltip-bg: $white;
$tooltip-opacity: 1;
$zindex-tooltip-backdrop: 1075 !default;
$tooltip-backdrop-bg: $dark !default;
$tooltip-backdrop-opacity: 0.5 !default;
$tooltip-arrow-width: 20px;
$tooltip-arrow-height: 10px;

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;
