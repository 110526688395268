.pko-dashboard {
  $self: &;

  @extend .pko-grid__row;
  @extend .pko-grid__row--12;

  &--simple {
    #{ $self }__area {
      &#{ $self }__area--center {
        @extend .col-md-8;
        @extend .col-lg-6;
        @include media-breakpoint-up(lg) {
          order: 2;
        }
      }

      &#{ $self }__area--left {
        @extend .col-sm-6;
        @extend .col-md-4;
        @extend .col-lg-3;
        @include media-breakpoint-up(lg) {
          order: 1;
        }
      }

      &#{ $self }__area--right {
        @extend .col-sm-6;
        @extend .col-md-4;
        @extend .col-lg-3;
        @include media-breakpoint-up(lg) {
          order: 3;
        }
      }
    }
  }

  &--pro {
    #{ $self }__area {
      &#{ $self }__area--center {
        @include media-breakpoint-down(md) {
          .pko-grid__row {
            margin-left: 0;
            margin-right: 0;
            & > app-indicatives {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        @include media-breakpoint-up(md) {
          order: 1;
        }
      }

      &#{ $self }__area--left {
        @extend .col-md-6;
        @include media-breakpoint-up(md) {
          order: 2;
        }
      }

      &#{ $self }__area--right {
        @extend .col-md-6;
        @include media-breakpoint-up(md) {
          order: 3;
        }
      }
    }
  }

  &__section {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding: 0 16px;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }

    &--solid {
      background-color: $white;
      padding: 16px;

      @include media-breakpoint-up(sm) {
        outline: 1px solid $gray-400;
        border-radius: 4px;
      }

      & #{ $self }__section-title {
        margin-top: 6px;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-title {
      @extend %text-lg;
      margin-bottom: 9px;
    }

    &-menu {
      padding: 6px;
    }

    .pko-form-footer {
      padding: 16px 0 8px;
    }
  }

  #{ $self }__area {
    @extend .col-12;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// -----------------------------------------------------------------------------
// Dashboard widgets selection
// -----------------------------------------------------------------------------
.tile-select label {
  cursor: pointer;
}

// -----------------------------------------------------------------------------
// Notifications widget
// -----------------------------------------------------------------------------
.pko-notifications {
  $self: &;
  .pko-group {
    &:not(:last-of-type) {
      app-notifications-main {
        &:last-of-type {
          .pko-notification {
            border-bottom: none;
          }
        }
      }
    }
  }

  #{ $self }__more {
    padding-bottom: 0;
  }
}

app-notifications-pro .pko-tabs .pko-tabs__item {
  margin: 0 16px;
}

.pko-notification {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 72px;
  padding: 11px 8px;
  border-bottom: 1px solid $gray-300;

  &__container {
    flex: 1;
    display: flex;

    &:not(.flex-column) {
      align-items: center;
    }
  }

  &__content {
    display: block;
    flex: 1 0;
  }

  &__icon {
    margin-right: 6px;
  }

  &__header {
    @extend %text-sm;
    margin-bottom: 4px;
  }

  &__message {
    @extend %text-xs;
    color: $text-muted;
  }
}

// -----------------------------------------------------------------------------
// Banner widget
// -----------------------------------------------------------------------------
.pko-banner {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
  background-color: $white;
  padding: 16px;
  border: 1px solid $gray-400;
  border-radius: 4px;
  min-height: 149px;
  cursor: pointer;

  &__container {
    display: flex;
    flex-direction: column;
    z-index: 1;

    @include media-breakpoint-up(md) {
      max-width: 164px;
    }
  }

  &__title {
    @include pko-font-size(21);
    @include pko-line-height(21, 26);
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    @include pko-font-size(15);
    @include pko-line-height(15, 19);
    margin-bottom: 0;
  }

  &__image {
    position: absolute;
    right: 16px;
  }
}

// -----------------------------------------------------------------------------
// Indicatives widget
// -----------------------------------------------------------------------------
.pko-indicatives {
  $self: &;

  &#{ $self }--pro {
    display: flex;
    position: relative;

    .pko-preloader__indicator {
      & ~ #{ $self }__footnote {
        z-index: 1;
      }
    }

    #{ $self }__list {
      width: 100%;
      position: relative;
      @include media-breakpoint-up(md) {
        max-width: 480px;
        padding-bottom: 15px;
      }

      .pko-indicative {
        padding-left: 8px;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm) {
          height: 96px;
          padding-left: 0;
        }

        &:not(.pko-indicative--selected) {
          @include media-breakpoint-up(sm) {
            margin: 0 4px;
          }

          .pko-indicative__chart {
            .pko-indicative__chart-button {
              display: none;
            }
          }

          &:not(:first-child) {
            border-top: 1px solid $gray-400;
          }
        }

        &:last-of-type {
          @include media-breakpoint-up(md) {
            margin-bottom: 24px;
          }
        }

        &.pko-indicative--selected {
          padding-right: 4px;
          padding-left: 16px;
        }

        .pko-indicative__rate {
          & + .pko-indicative__rate {
            @include media-breakpoint-down(sm) {
              margin-left: 20px;
              margin-right: 0;
            }
          }

          .pko-ticking-rate {
            @include media-breakpoint-down(sm) {
              background-color: $gray-100;
              border-color: $gray-300;
            }

            .pko-ticking-rate__key {
              @include media-breakpoint-down(sm) {
                @include pko-font-size(13);
                @include pko-line-height(13, 16);
              }
            }
          }
        }
      }

      & + #{ $self }__graph {
        app-form-side {
          app-form-button-group {
            margin-top: 8px;
            margin-bottom: 4px;
          }
        }

        @include media-breakpoint-up(md) {
          margin-left: var(--bs-gutter-x);
          flex: 1;
        }
      }
    }

    #{ $self }__footnote {
      @include media-breakpoint-up(md) {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: auto;
        padding: 0;
      }
      padding-bottom: 0;
    }

    #{ $self }__graph {
      display: flex;
      flex-direction: column;

      app-alert {
        flex: 1;
        display: flex;

        .pko-alert {
          flex: 1 1;
          justify-content: center;
        }
      }
    }
  }

  #{ $self }__accordion {
    .pko-indicative {
      height: auto;
      border-left: none;
      border-right: none;
      &:first-of-type {
        border-radius: 0;

        .pko-indicative__button {
          border-radius: 0;
        }
      }

      &:last-of-type {
        border-radius: 0;

        .pko-indicative__button {
          &.collapsed {
            border-radius: 0;
          }
        }

        .pko-indicative__button {
          border-radius: 0;
        }
      }

      .accordion-header {
        height: 80px;
        display: flex;
        align-items: center;
        @include media-breakpoint-up(sm) {
          padding-left: 22px;
          padding-right: 16px;
        }
      }

      .pko-indicative__content {
        flex: 1;

        .pko-indicative__currency-pair {
          app-form-control {
            .pko-flag--left {
              margin-right: 8px;
            }

            .pko-flag__label,
            .input-group__text {
              @include pko-font-size(17);
              @include pko-line-height(17, 20);
            }
            .input-group__text {
              padding-left: 7px;
              padding-right: 8px;
            }
          }
        }

        .pko-indicative__rate {
          width: 96px;
          .pko-ticking-rate__key {
            @extend %text-sm;
            margin-bottom: 4px;
          }

          .pko-ticking-rate__value {
            @include pko-font-size(17);
            @include pko-line-height(17, 24);
          }

          & + .pko-indicative__rate {
            @include media-breakpoint-up(sm) {
              margin-left: 56px;
              margin-right: 32px;
            }
            @include media-breakpoint-up(md) {
              margin-left: 40px;
              margin-right: 12px;
            }
            @include media-breakpoint-up(lg) {
              margin-left: 30px;
            }
          }
        }
      }

      &__button {
        @extend %text-sm;
        min-width: 110px;
        justify-content: end;
        &::after {
          margin-left: 0;
          background: url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6ICMwMDM1NzQ7CiAgICAgIH0KICAgICAgLmIgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPgogICAgPHBhdGgKICAgICAgY2xhc3M9ImEiCiAgICAgIGQ9Ik0xMiwxNC4zNzRoMEw2LjkzLDEwLjEyYTEuODgxLDEuODgxLDAsMCwxLDEuMTc5LS40OTQsMS43MjcsMS43MjcsMCwwLDEsMS4xMTEuNDU3TDEyLDEyLjQxNmwyLjc3OS0yLjMzM2ExLjczNSwxLjczNSwwLDAsMSwxLjExMi0uNDU3LDEuODY5LDEuODY5LDAsMCwxLDEuMTc5LjQ5M0wxMiwxNC4zNzRaIgogICAgLz4KICAgIDxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIC8+CiAgPC9nPgo8L3N2Zz4K);
        }
      }
    }
  }

  &__list {
    flex-shrink: 0;
  }
  &__graph {
    margin: 0 20px;
    display: block;
  }
}

.pko-indicative {
  $self: &;
  height: 64px;

  &#{$self}--selected {
    border: 1px solid $gray-300;
    box-shadow: 0 6px 10px rgba($gray-600, 0.16);
    height: 80px;
    padding-right: 4px;
    padding-left: 16px;

    #{ $self }__currency-pair {
      app-form-control {
        margin-bottom: 0;

        .pko-flag--left {
          margin-right: 8px;
        }
        .pko-flag__label,
        .input-group__text {
          @include pko-font-size(17);
          @include pko-line-height(17, 20);
        }

        .input-group__text {
          padding-left: 4px;
          padding-right: 8px;
        }
      }
    }

    #{ $self }__chart {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    #{ $self }__rate {
      width: 94px;
      .pko-ticking-rate {
        padding: 8px 12px 6px 12px;

        &__key {
          @extend %text-sm;
          margin-bottom: 4px;
        }

        &__value {
          @include pko-font-size(17);
          @include pko-line-height(17, 24);
        }
      }

      & + #{ $self }__rate {
        margin-left: 12px;
        margin-right: 8px;
      }
    }

    #{ $self }__select {
      display: flex;
      align-items: center;
      @include pko-font-size(15);
      @include pko-line-height(15, 20);
      &::before {
        content: "";
        border-left: 1px solid $gray-300;
        height: 48px;
      }

      .ng-value-container {
        padding-left: 16px;
      }
    }
  }

  .pko-preloader__indicator {
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
  }

  #{ $self }__content {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  #{ $self }__currency-pair {
    display: none;
    margin-right: auto;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    &--pro {
      cursor: pointer;
    }

    app-form-control {
      margin-bottom: 0;

      .pko-flag--left {
        margin-right: 4px;
      }

      .pko-flag__label,
      .input-group__text {
        @include pko-font-size(13);
        @include pko-line-height(13, 20);
        font-weight: 700;
      }
      .input-group {
        flex-wrap: nowrap;
        &__text {
          padding-left: 3px;
          padding-right: 4px;
        }
      }
    }
  }

  #{ $self }__chart {
    margin-left: 0;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(sm) {
      align-items: center;
      margin-left: 16px;
      margin-right: 16px;
    }

    #{ $self }__chart-graph {
      // Exception: Do not use !important, this its exception because of highcharts
      width: 74px !important;
    }

    &-label {
      @extend %text-md;
      font-weight: 700;
    }

    &-button {
      @extend .btn;
      @extend .btn-link;
      @extend .btn-xs;

      &#{ $self }____chart-button--icon {
        @include pko-font-size(11);
        @include pko-line-height(11, 11);
        align-items: center;
        display: inline-flex;
        height: 24px;
        padding: 0;
        color: $primary-light;
        &:hover {
          color: $primary;
        }
        svg {
          margin-left: 4px;
        }
      }
    }

    &-blank {
      @extend %text-xs;
      width: 74px;
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
    }
  }

  #{ $self }__rate {
    width: 80px;
    .pko-ticking-rate {
      padding: 7px 8px 5px 8px;
      border: 1px solid transparent;
      border-radius: $border-radius;
      @include transition($transition-base);

      &:hover {
        border-color: $gray-300;
        background-color: $gray-100;
      }

      &__key {
        @extend %text-xs;
        margin-bottom: 4px;
      }

      &__value {
        @include pko-font-size(15);
        @include pko-line-height(15, 24);
        font-weight: 700;

        &--no-rate {
          @include pko-font-size(12);
        }
      }
    }

    & + #{ $self }__rate {
      margin-left: 8px;
      margin-right: 12px;
    }
  }

  #{ $self }__select {
    @include pko-font-size(13);
    @include pko-line-height(13, 20);

    .ng-select-container {
      border: none;
    }

    .ng-value-container {
      padding-left: 8px;
    }

    .ng-dropdown-panel {
      min-width: 140px;
    }
  }

  #{ $self }__break {
    height: 0;
    flex-basis: 100%;
  }

  #{ $self }__tenor {
    @include pko-font-size(11);
    @include pko-line-height(11, 11);
    margin-bottom: 0;
  }
}

// -----------------------------------------------------------------------------
// Recent History widget
// -----------------------------------------------------------------------------
.pko-recent-history {
  $self: &;
  display: flex;
  flex-direction: column;

  #{$self}__item {
    display: block;
    padding: 12px 8px;
    border-bottom: 1px solid $gray-300;

    &-status {
      @extend %text-sm;
      margin-bottom: 0;
    }

    &-deal {
      padding-top: 0;
      padding-bottom: 0;
      &:first-of-type {
        padding-top: 8px;
      }
    }
  }

  app-unsettled-history-item .pko-recent-history__item-deal:first-child > label {
    @extend %text-md;
  }

  .pko-group {
    &:not(:last-of-type) {
      #{$self}__item {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  #{ $self }__more {
    margin: 24px auto 0;
  }
}
