.pko-month-picker {
  position: relative;
  &__container {
    position: absolute;
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 5px;
    border-color: grey;
    border-width: 1px;
    border-style: solid;
    z-index: 1;
    margin-top: 0.5rem;
  }
  &__month,
  &__year {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
  }

  &__up {
    transform: rotate(270deg);
    z-index: 5;
  }
  &__down {
    transform: rotate(90deg);
    z-index: 5;
    svg {
      pointer-events: none;
    }
  }
  &__scrolling-val {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjYwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjAiIHgyPSIwIiB5MT0iMCIgeTI9IjEiPjxzdG9wIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjZmZmIiBvZmZzZXQ9IjMzJSIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmYiIG9mZnNldD0iNjclIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNhKSIvPjwvc3ZnPg==);
    mask-mode: luminance;
    mask-clip: content-box;
    height: 60px;
    span.pko-month-picker__invalid {
      color: #eaeaea;
    }
  }
}
