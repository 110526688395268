// -----------------------------------------------------------------------------
// Navbar
// -----------------------------------------------------------------------------

%navLinkSelected {
  .pko-nav__link-name {
    color: $primary;
    font-weight: 700;
  }
  .pko-nav__link-icon {
    --color6: var(--bs-primary);
  }
}

app-nav-menu {
  min-height: 48px;
}

.pko-navbar {
  $self: &;
  background: $white;
  box-shadow: 0px 3px 6px rgba($dark, 0.08);
  height: 48px;
  justify-content: start;
  padding: 0;
  @include media-breakpoint-up(md) {
    height: 56px;
  }
  @include media-breakpoint-up(lg) {
    height: 80px;
  }

  &#{ $self }--mobile {
    @extend .fixed-bottom;
    box-shadow: 0 -3px 6px rgb($dark, 0.08);
    height: 64px;
    @include media-breakpoint-up(sm) {
      height: 80px;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }

    &#{ $self }--over {
      z-index: $zindex-popover;
    }

    .pko-nav {
      padding-left: 0;
      margin: 0 auto;
      @include media-breakpoint-down(lg) {
        display: flex;
      }

      &__item {
        &:focus,
        &:focus-visible {
          outline: none;
        }

        &--hidden {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &--more {
          text-transform: lowercase;
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }

        &--selected {
          @extend %navLinkSelected;
        }

        &--collapse {
          &:not(:first-child) {
            margin-left: 35px;
            @include media-breakpoint-up(md) {
              margin-left: 40px;
            }
          }

          .pko-nav__link {
            cursor: pointer;

            &::after {
              content: none;
            }

            &-name {
              &::before {
                content: none;
              }

              &::after {
                content: none;
              }
            }
          }
        }

        &:not(:first-child) {
          margin-left: 35px;
          @include media-breakpoint-up(md) {
            margin-left: 40px;
          }
        }

        &.active {
          @extend %navLinkSelected;
        }
      }

      &__link {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          .pko-nav__link-name {
            color: $primary;
          }
          .pko-nav__link-icon {
            --color6: var(--bs-primary);
          }
        }

        &:focus,
        &:focus-visible {
          outline: none;
        }

        &-icon {
          --color6: var(--bs-gray-600);
          display: inline-block;
        }
        &-name {
          @include pko-font-size(11);
          @include pko-line-height(11, 14);
          padding: 0;
        }
      }
    }
  }

  &__return {
    border-right: 1px solid $gray-200;
    height: 100%;
    width: 48px;
    cursor: pointer;
    &:hover {
      background: $gray-200;
    }
    @include media-breakpoint-up(md) {
      width: 64px;
    }
    @include media-breakpoint-up(lg) {
      width: 92px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 100%;

      span {
        @extend %text-sm;
        color: $dark;
        margin-top: 4px;
      }
    }
  }

  &__brand {
    padding-left: 16px;
    padding-right: 8px;
    margin-right: auto;
    @include media-breakpoint-between(sm, lg) {
      margin-left: auto;
      padding-left: 88px;
      padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0;
      padding-right: 0;
    }
  }

  .pko-nav {
    padding-left: 24px;
    margin-right: auto;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  // -----------------------------------------------------------------------------
  // Language switcher
  // -----------------------------------------------------------------------------

  app-language {
    &#{ $self }__language {
      margin-left: auto;

      @media (max-width: 1366px) {
        display: none;
      }

      app-select {
        .ng-select {
          .ng-select-container {
            border: none;
            text-transform: uppercase;
            @extend %text-sm;
          }

          .ng-option {
            text-transform: uppercase;
            @extend %text-sm;
          }

          .ng-arrow-wrapper {
            .ng-arrow {
              background-position: 50%;
              background: url("data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6ICMwMDM1NzQ7CiAgICAgIH0KICAgICAgLmIgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPgogICAgPHBhdGgKICAgICAgY2xhc3M9ImEiCiAgICAgIGQ9Ik0xMiwxNC4zNzRoMEw2LjkzLDEwLjEyYTEuODgxLDEuODgxLDAsMCwxLDEuMTc5LS40OTQsMS43MjcsMS43MjcsMCwwLDEsMS4xMTEuNDU3TDEyLDEyLjQxNmwyLjc3OS0yLjMzM2ExLjczNSwxLjczNSwwLDAsMSwxLjExMi0uNDU3LDEuODY5LDEuODY5LDAsMCwxLDEuMTc5LjQ5M0wxMiwxNC4zNzRaIgogICAgLz4KICAgIDxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIC8+CiAgPC9nPgo8L3N2Zz4K");
            }
          }
        }
      }
    }
  }

  .pko-profile {
    padding: 0 4px;
    @include media-breakpoint-up(sm) {
      padding: 4px 16px;
    }
    @include media-breakpoint-up(md) {
      padding: 8px 16px;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px 15px;
      border-left: 1px solid $gray-200;
    }
    transition: background 0.15s ease-in-out;

    &:hover {
      &:not(.show) {
        background: $gray-200;
      }
    }
  }

  .pko-logout {
    border-left: 1px solid $gray-200;
    width: 48px;
    @include media-breakpoint-up(md) {
      width: 64px;
    }
    @include media-breakpoint-up(lg) {
      width: 96px;
    }

    &:hover {
      background: $gray-200;
    }

    &__title {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Nav
// -----------------------------------------------------------------------------

.pko-nav {
  $self: &;

  align-items: center;
  flex-direction: row;

  #{ $self }__item {
    &--selected {
      #{ $self }__link-name {
        color: $primary;
        &::before {
          width: 100%;
        }
        &::after {
          transform: rotate(-180deg);
        }
      }
    }
    &--collapse {
      &:not(:first-child) {
        margin-left: 36px;
      }

      #{ $self }__link {
        cursor: pointer;
        &::after {
          content: none;
        }

        &-name {
          &::before {
            margin-left: -4px;
            padding-left: 0;
          }

          &::after {
            content: "";
            background-position: 50%;
            background: url("data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6ICMwMDM1NzQ7CiAgICAgIH0KICAgICAgLmIgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPgogICAgPHBhdGgKICAgICAgY2xhc3M9ImEiCiAgICAgIGQ9Ik0xMiwxNC4zNzRoMEw2LjkzLDEwLjEyYTEuODgxLDEuODgxLDAsMCwxLDEuMTc5LS40OTQsMS43MjcsMS43MjcsMCwwLDEsMS4xMTEuNDU3TDEyLDEyLjQxNmwyLjc3OS0yLjMzM2ExLjczNSwxLjczNSwwLDAsMSwxLjExMi0uNDU3LDEuODY5LDEuODY5LDAsMCwxLDEuMTc5LjQ5M0wxMiwxNC4zNzRaIgogICAgLz4KICAgIDxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIC8+CiAgPC9nPgo8L3N2Zz4K");
            display: inline-block;
            height: 24px;
            transition-duration: 0.3s;
            width: 24px;
          }
        }
      }
    }

    &:not(:first-child) {
      margin-left: 40px;
      @media (max-width: 1484px) {
        margin-left: 36px;
      }
    }

    &.active {
      & #{ $self }__link-name {
        color: $primary;
        &::before {
          width: 100%;
        }
      }
    }
  }

  #{ $self }__link {
    &-icon {
      display: none;
    }

    &-name {
      @include pko-font-size(17);
      @include pko-line-height(17, 24);
      display: flex;
      padding-bottom: 1px;
      padding-top: 7px;
      position: relative;
      color: $dark;

      &:hover {
        color: $primary;
      }

      &:before {
        content: "";
        background-color: $primary;
        border-radius: 5px 0;
        bottom: -26px;
        display: block;
        height: 5px;
        left: 50%;
        position: absolute;
        transform-origin: center;
        transform: skew(-25deg) translateX(-50%);
        transition: width 0.3s ease-in-out;
        width: 0;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Nav collapse
// -----------------------------------------------------------------------------

.pko-collapse-menu {
  $self: &;
  background: $white;
  box-shadow: inset 0 24px 16px -16px rgba($gray-200, 0.75), 0 6px 11px rgba($dark, 0.16);

  &__container {
    padding: 24px 0;
  }

  #{ $self }__card {
    display: flex;
    border: 1px solid $gray-300;
    border-radius: 4px;
    align-items: center;
    height: 94px;
    padding: 20px 16px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 8px rgb($dark, 0.08);
    }

    &-container {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      padding-right: 12px;
    }
    &-icon {
      width: 38px;
      height: 38px;
      flex-shrink: 0;
    }
    &-title {
      @include pko-font-size(17);
      @include pko-line-height(17, 24);
      margin-bottom: 0;
    }
    &-description {
      @extend %text-sm;
      font-weight: 300;
      margin-top: 2px;
      margin-bottom: 0;
    }
  }
}

// -----------------------------------------------------------------------------
// TitleBar
// -----------------------------------------------------------------------------

.pko-titlebar {
  $self: &;
  align-items: center;
  background: $white;
  box-shadow: 0px 3px 6px rgba($dark, 0.08);
  display: flex;
  min-height: 48px;
  justify-content: space-evenly;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // Bigger than buttons z-index in form controls
  z-index: 6;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }

  #{ $self }__button {
    height: 100%;
    flex-shrink: 0;
    width: 48px;
    border: none;
    padding: 0;
    @include media-breakpoint-up(md) {
      height: 56px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &__title {
    @extend %text-md;
    padding: 10px 10px 10px 0;
    margin: 0px auto;

    + img {
      height: 40px;
    }
  }
}

.pko-submenu {
  $self: &;
  margin-top: 16px;
  margin-bottom: 0;
  padding-left: 0;

  &:last-child {
    margin-bottom: 32px;
  }

  &#{ $self }--hidden {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #{ $self }__item {
    display: flex;
    align-items: center;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    background-color: $white;
    min-height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;

    &-name {
      @extend %text-md;
      color: $primary;
      padding-left: 40px;

      &:hover {
        color: $dark;
      }
    }
    &-icon {
      width: 28px;
      height: 28px;
      margin: 2px;
    }

    &--title {
      min-height: 56px;

      #{ $self }__item-name {
        @include pko-font-size(17);
        @include pko-line-height(17, 24);
        color: $dark;
        padding-left: 8px;

        @include media-breakpoint-up(sm) {
          padding-left: 10px;
        }
      }
    }
    &--profile {
      min-height: 68px;

      #{ $self }__item-icon {
        //icon margin + (icon height - item-name height)
        margin-top: 10px;
        align-self: flex-start;
      }

      #{ $self }__item-container {
        padding-left: 8px;

        @include media-breakpoint-up(sm) {
          padding-left: 10px;
        }

        #{ $self }__item-name {
          padding-left: 0;
        }

        #{ $self }__item-description {
          @extend %text-xs;
          color: $text-muted;
          padding-left: 0;
          margin-top: 2px;
        }
      }
    }
  }
}
