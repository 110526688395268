.modal-dialog {
  max-width: 100%;
  position: relative;
  @include media-breakpoint-up(sm) {
    max-width: 580px;
  }
  @include media-breakpoint-up(md) {
    max-width: 650px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 810px;
  }

  &.modal-xl {
    @include media-breakpoint-up(sm) {
      max-width: 702px;
    }
    @include media-breakpoint-up(md) {
      max-width: 820px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 1020px;
    }

    .pko-modal {
      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
      }
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(10, 55px);
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(10, 75px);
      }

      .pko-modal__container {
        grid-column: auto / span 4;

        @include media-breakpoint-up(sm) {
          grid-column: 2 / span 10;
        }

        @include media-breakpoint-up(md) {
          grid-column: 2 / span 8;
        }

        .pko-modal__grid {
          @include media-breakpoint-up(md) {
            --bs-columns: 10;
          }

          @include media-breakpoint-up(lg) {
            --bs-columns: 8;
          }
        }
      }
    }
  }
  &.modal-fullscreen {
    max-width: none;
  }
  // Scrollable modal content
  &.modal-dialog-scrollable {
    @include media-breakpoint-up(sm) {
      height: auto;
      max-height: calc(100% - var(--bs-modal-margin) * 2);
    }
    .modal-content {
      align-self: safe center;
    }

    .pko-modal {
      .pko-modal__container {
        display: flex;
        min-height: 0;
        // Safari fix
        flex-basis: 100%;

        .pko-modal__header {
          flex: 0;
        }

        .pko-modal__body {
          flex: 1;
          // Safari fix
          flex-basis: 100%;
          overflow-y: auto;
          position: relative;

          @include media-breakpoint-up(sm) {
            // Solution to moving sidebar 24px from the edge of modal
            // Calc: -(margin - 24px (from scrollbar to edge) + 3px (scrollbar/2))
            margin-right: -40px;
            // Calc:  margin - 24px (from scrollbar to edge) - 6px (scrollbar)
            padding-right: 34px;
            scrollbar-color: $primary $gray-400;
            scrollbar-width: thin;
            min-height: 120px;

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-track {
              background: $gray-400;
              border-left: 2px solid $white;
              border-right: 2px solid $white;
              border-radius: 1em;
            }

            &::-webkit-scrollbar-thumb {
              background: $primary;
              border-left: 1px solid $white;
              border-right: 1px solid $white;
              border-radius: 1em;

              &:hover {
                border-left: 1px solid $white;
                border-right: 1px solid $white;
                border-radius: 1em;
              }
            }
          }

          @include media-breakpoint-up(md) {
            margin-right: -64px;
            padding-right: 58px;
          }

          @include media-breakpoint-up(lg) {
            margin-right: -84px;
            padding-right: 78px;
          }
        }
      }

      .pko-modal__footer {
        flex: 0;
      }
    }
  }
}

.modal-menu {
  // Hide menu modal if opened before closing trough observable
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .modal-content {
    @extend .bg-body-light;
    padding: 0 0 64px;

    @include media-breakpoint-up(sm) {
      padding: 0 0 80px;
    }
    .pko-modal {
      display: flex;
      flex-direction: column;
      height: 100%;

      .pko-modal__container {
        overflow-y: auto;
        position: relative;
        flex: 1 1 auto;
      }
    }
  }
}

.modal-attorneys {
  .modal-content {
    padding: 48px 0 24px;

    .pko-modal__header,
    .pko-modal__body,
    .pko-modal__footer {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.modal-details {
  // Hide menu modal if opened before closing trough observable
  @include media-breakpoint-up(sm) {
    display: none;
  }
  .modal-content {
    overflow: scroll;
    height: 100%;
    @extend .bg-body-light;
    padding: 0;

    .pko-details,
    .pko-details__container {
      display: flex;
      flex-direction: column;
    }

    app-exchange-details,
    app-order-details,
    app-deposit-details,
    app-investment-deposit-details,
    app-view360-review,
    app-mobile-graph {
      @extend .pko-modal;

      .nav-tabs .nav-link.active {
        background-color: unset;
      }
    }

    app-deposit-details,
    app-investment-deposit-details {
      app-detail-container {
        .pko-details__content {
          @extend .pko-modal__container;
          padding: 24px 16px;
        }
      }
    }

    app-simple-exchange-details,
    app-order-details-content,
    app-swap-details,
    .pko-details__main {
      @extend .pko-modal__container;
      padding: 24px 16px;

      .pko-details__footer {
        // Footer fix in modal details
        padding: 0;
      }
    }

    .pko-modal__container {
      @include media-breakpoint-up(sm) {
        overflow-y: auto;
      }
      position: relative;
      flex: 1 1 auto;
    }
  }
}

.modal-content {
  height: 100%;
  box-shadow: 0px 2px 4px rgba($dark, 0.3);
  padding: 48px 16px 24px;

  @include media-breakpoint-up(sm) {
    padding: 16px 0 24px;
  }
}

.pko-modal {
  $self: &;
  @extend %modal-base;

  &__close {
    @extend .btn;
    @extend .btn-link;
  }

  #{ $self }__container {
    @extend %modal-container;
    // Fix for preloader
    min-height: 60px;

    .pko-modal__body {
      .pko-form-footer {
        .pko-form-footer__countdown {
          padding: 0 16px;
        }
      }
    }
  }

  #{ $self }__header {
    padding-top: 8px;

    @include media-breakpoint-up(sm) {
      padding-top: 27px;
    }

    #{ $self }__close {
      @extend %modal-header-close;
    }
  }

  #{ $self }__titlebar {
    align-items: center;
    background: $white;
    box-shadow: 0px 3px 6px rgba($dark, 0.08);
    display: flex;
    height: 48px;
    justify-content: space-evenly;
    width: 100%;
    flex-shrink: 0;
    // Bigger than buttons z-index in form controls
    z-index: 6;

    @include media-breakpoint-up(sm) {
      height: 56px;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    #{ $self }__titlebar-button {
      height: 48px;
      left: 0;
      position: absolute;
      width: 48px;
      border: none;
      padding: 0;
      @include media-breakpoint-up(md) {
        height: 56px;
      }

      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &-title {
      @extend %text-md;
    }
  }

  #{ $self }__footer {
    @extend %modal-footer;
  }

  #{ $self }__title {
    @extend %modal-title;
  }

  #{ $self }__grid {
    @extend %modal-grid;
  }
}

%modal-base {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: $grid-gutter-width-xs;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-column-gap: $grid-gutter-width;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(8, 55px);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(8, 75px);
  }
}

%modal-container {
  display: flex;
  flex-direction: column;
  grid-row: 1;
  grid-column: auto/span 4;

  @include media-breakpoint-up(sm) {
    grid-column: 2 / span 8;
  }

  @include media-breakpoint-up(md) {
    grid-column: 2 / span 6;
  }
}

%modal-grid {
  --bs-columns: 4;
  display: grid;
  grid-template-rows: repeat(var(--#{$variable-prefix}rows, 1), 1fr);
  grid-template-columns: repeat(
    var(--#{$variable-prefix}columns, #{$grid-columns}),
    minmax(0, 1fr)
  );
  gap: var(--#{$variable-prefix}gap, #{$grid-gutter-width});
  row-gap: 0;
  @include make-cssgrid();

  @include media-breakpoint-up(sm) {
    --bs-columns: 8;
  }

  @include media-breakpoint-up(md) {
    --bs-columns: 6;
  }
}

%modal-footer {
  margin-top: auto;
  padding: 24px 0 8px;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    margin-top: 0;
  }

  .btn {
    &:not(:last-child) {
      @include media-breakpoint-up(sm) {
        margin-right: 30px;
      }
    }
  }
}

%modal-header-close {
  border: none;
  height: 48px;
  margin-left: auto;
  min-height: auto;
  padding: 0;
  width: 48px;
  position: absolute;
  right: 0;
  top: 0;
}

ngb-modal-window:not(:last-of-type),
ngb-modal-backdrop:not(:last-of-type) {
  display: none !important;
}

.modal-fullscreen-sm-down {
  .modal-content {
    overflow: auto;
  }
}
