.pko-page {
  $self: &;

  #{ $self }__header {
    display: flex;
    flex-direction: column;

    #{ $self }__icon {
      padding-top: 16px;

      & + #{ $self }__title {
        padding-bottom: 36px;
      }
    }

    #{ $self }__title {
      margin-bottom: 0;
      padding-top: 16px;
    }
  }

  #{ $self }__icon {
    height: 96px;
    width: 96px;
    &--danger {
      @extend %svg-color-danger;
    }
  }

  #{ $self }__title {
    @extend %modal-title;
    &--danger {
      color: var(--bs-danger);
    }
  }

  #{ $self }__container {
    @extend %modal-container;
    &--center {
      #{ $self }__header,
      #{ $self }__body {
        align-items: center;
        text-align: center;
      }

      #{ $self }__body {
        min-height: 72px;
        .pko-br {
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
      }
    }
  }

  #{ $self }__footer {
    @extend %modal-footer;
    .btn-fixed {
      @include media-breakpoint-up(sm) {
        width: 240px;
      }
    }
  }
}
