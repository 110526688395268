.pko-list-group {
  & > .list-group-item-action {
    &:last-of-type {
      &:not(.expanded) {
        border-bottom-color: transparent;
      }
    }
  }
  .list-group {
    &.collapse {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
    &-item {
      padding: 0.75rem 1rem;
      color: $text-color;
      border-right: 1px solid $list-group-border-color;
      transition: $btn-transition;

      &.selected {
        background-color: $light;
        border-right: 3px solid $primary;
        color: $link-hover-color;
      }

      &-action {
        display: inline-flex;
        align-items: center;
        @include pko-font-size(17);
        @include pko-line-height(17, 24);

        &--collapse {
          &[aria-expanded="true"] {
            &::after {
              transform: rotate(180deg);
            }
          }
          &::after {
            content: "";
            background: url(data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=)
              no-repeat;
            height: 24px;
            width: 24px;
            display: block;
            flex-shrink: 0;
            margin-left: auto;
            position: relative;
            transition: transform 300ms ease-in-out;
          }
          & + .list-group {
            .list-group-item {
              padding: 1rem 1rem 1rem 4rem;
              color: $primary;
              border-bottom-width: 0;
            }
          }
        }

        i {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          &.pi {
            color: $primary;
            @include pko-font-size(24);
          }
        }
      }
    }
  }
}
