.pko-modal--chart {
  .pko-modal__grid {
    position: relative;

    app-form-button-group {
      @include media-breakpoint-up(lg) {
        margin-top: 30px;
        margin-bottom: 18px;
      }

      .pko-btn-group__label {
        @include media-breakpoint-up(lg) {
          min-height: 40px;
          @include pko-font-size(13);
          @include pko-line-height(13, 16);
          padding: 11px 16px;
        }
      }
    }

    app-form-currency-pair {
      @include media-breakpoint-down(sm) {
        order: -1;
        margin-top: 0;
      }
    }

    .highcharts-range-selector-buttons {
      @include media-breakpoint-down(sm) {
        transform: translate(calc(50% - 105.5px), 216px);
      }
    }

    app-alert {
      grid-column: auto/span 10;
    }
  }

  .pko-graph__footer {
    // infotext-height (48px) + chart rangeSelector margin (16px) + half of footer height (21px/2 = 10.5px)
    @include media-breakpoint-up(lg) {
      bottom: 66.5px;
    }
    // infotext-heigth (64px)
    @include media-breakpoint-down(lg) {
      bottom: 82.5px;
    }

    right: 0;
    width: auto;
  }
}

.pko-graph {
  $self: &;
  position: relative;

  &__modal-content {
    margin: 5px -15px;
    padding: 0px 15px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 32px;
    width: 100%;
  }

  &__actions {
    display: block;
    flex-direction: row;
    width: 100%;

    #{ $self }__button {
      &:not(:first-child) {
        margin-left: 32px;
      }

      &#{ $self }__button--icon {
        @extend %text-md;
        height: 48px;
        padding: 0;

        svg {
          margin-left: 8px;
        }
      }
    }
  }

  &__button {
    @extend .btn;
    @extend .btn-link;
    @extend .btn-xs;

    &#{ $self }__button--icon {
      @include pko-font-size(11);
      @include pko-line-height(11, 11);
      align-items: center;
      display: inline-flex;
      margin-left: auto;
      height: 24px;
      padding: 0;
      color: $primary-light;
      &:hover {
        color: $primary-dark;
      }
      svg {
        margin-left: 4px;
      }
    }

    &#{ $self }__button--link {
      @include pko-font-size(10);
      @include pko-line-height(10, 10);
      padding: 4px 0;
      min-height: 21px;
    }

    &#{ $self }__button--selected {
      color: $dark;
      font-weight: 700;
    }

    &-separator {
      border: 1px solid $gray-400;
      margin: 0 16px;
      border-top: none;
    }
  }
}

.pko-blotter__legend-item {
  margin: 5px 15px;

  &::before {
    content: " ";
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }

  &:nth-child(1) {
    &::before {
      background: #00468c;
    }
  }

  &:nth-child(2) {
    &::before {
      background: #e4202c;
    }
  }

  &:nth-child(3) {
    &::before {
      background: #8a8a8a;
    }
  }

  &:nth-child(4) {
    &::before {
      background: #ac8121;
    }
  }

  &:nth-child(5) {
    &::before {
      background: #000000;
    }
  }
}
