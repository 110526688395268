.pko-radio-stack {
  $self: &;

  display: block;
  margin-top: 24px;
  @include media-breakpoint-up(sm) {
    margin-top: 32px;
  }

  #{ $self }__container {
    display: block;
    margin-top: 8px;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-left: 16px;
    }
  }

  &__item {
    padding: 16px;
    border: 1px solid $gray-400;
    border-radius: 4px;
    position: relative;
    background-color: $white;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
    }

    &:first-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-width: 0;
    }

    &#{ $self }__item--checked {
      border-color: $primary;
      border-width: 2px;
      border-radius: 4px;
      margin: -1px;
      z-index: 1;
    }

    &-label {
      @include pko-font-size(15);
      @include pko-line-height(15, 24);
      font-weight: 700;
    }

    &-description {
      @extend %text-xs;
      color: $text-muted;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      text-align: start;
    }

    &::before {
      @include media-breakpoint-up(sm) {
        content: "";
        border-left: 1px solid $gray-400;
        height: 62px;
        display: block;
        margin-left: 8px;
      }
    }
  }

  &__input {
    accent-color: $primary;
    height: 20px;
    position: absolute;
    width: 20px;
    @include media-breakpoint-up(sm) {
      position: static;
      margin: 14px;
    }
  }

  #{ $self }__icon {
    width: 58px;
    height: 32px;
    @include media-breakpoint-up(sm) {
      margin-left: 16px;
    }
  }
}

.pko-dpw-wizard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .pko-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .pko-grid__row {
      margin-bottom: auto;
    }
  }
}
