.pko-flow {
  &__table {
    app-form-control {
      margin-bottom: 0px;
    }
  }

  &__item {
    @extend .flex-grow-1;
    @extend .mb-1;
    font-size: 16px;

    &-amount {
      @extend .align-self-center;
      @include pko-font-size(18);
      @include pko-line-height(18, 24);
    }
  }

  &__container {
    &-footer {
      display: flex;
      flex-direction: column;
      padding: 10px 0 8px;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        margin-top: 0;
      }

      .btn {
        min-width: 135px;
        &:not(:last-child) {
          @include media-breakpoint-up(sm) {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
