// -----------------------------------------------------------------------------
// Root variables
// -----------------------------------------------------------------------------
:root {
  // Colors from icons
  @extend %svg-colors;
}

%svg-colors {
  --color6: var(--bs-primary);
  // --color7: var(--bs-white);     // <<--- NOWE
  --color7: #fff; // <<--- NOWE
}

%svg-colors-invert {
  // --color6: var(--bs-white);     // <<--- NOWE
  --color6: #fff; // <<--- NOWE
  --color7: var(--bs-primary);
}

%svg-color-danger {
  --color5: var(--bs-danger);
  --color6: var(--bs-danger);
}

%svg-color-success {
  --color6: var(--bs-success);
}

%svg-colors-gray {
  --color6: var(--bs-gray-dark);
}

body {
  position: relative;
}
