.pko-group {
  $self: &;
  &:not(:first-of-type) {
    #{ $self }__header {
      border-top: none;
    }
  }

  #{ $self }__header {
    @extend %text-sm;
    background: $gray-100;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    height: 32px;
    padding: 8px 16px;

    @include media-breakpoint-up(sm) {
      padding: 5px 8px;
      height: 24px;
      @include pko-font-size(11);
      @include pko-line-height(11, 14);
    }
  }

  #{ $self }__item {
    &--column {
      flex-direction: column;

      .btn {
        margin-top: 8px;
      }
    }
    @include pko-font-size(15);
    @include pko-line-height(15, 16);
    border-bottom: 1px solid $gray-300;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 16px;
  }
}

.pko-empty {
  $self: &;

  &--group {
    min-height: 128px;
    background-color: $gray-a100;
    margin: 0 -16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;

    #{ $self }__message {
      @extend %text-md;
      margin-bottom: 0;
      text-align: center;
    }
  }
}
