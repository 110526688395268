// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

@font-face {
  font-family: "pkobp";
  src: url("../assets/fonts/pkobankpolski-light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "pkobp";
  src: url("../assets/fonts/pkobankpolski-regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "pkobp";
  src: url("../assets/fonts/pkobankpolski-bold-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
