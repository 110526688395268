// -----------------------------------------------------------------------------
// Controls
// -----------------------------------------------------------------------------

.ng-invalid.ng-touched,
.ng-invalid.ng-dirty {
  &.form-control {
    @extend .is-invalid;
  }

  &.ng-select {
    .ng-select-container {
      border-color: $danger;
    }
  }
}

.form-summary {
  .form-control {
    background-color: transparent;
    border: none;
    text-align: center;
  }
}

// Button group
.btn-check {
  &:checked {
    & + .btn {
      &.btn--pointed {
        z-index: 1;

        &::after {
          background-color: inherit;
          border-bottom: 1px solid $primary;
          border-right: 1px solid $primary;
          bottom: -9px;
          content: "";
          display: block;
          height: 1rem;
          left: 50%;
          position: absolute;
          transform: rotate(45deg);
          width: 1rem;
          z-index: 0;

          @include media-breakpoint-up(sm) {
            height: 8px;
            width: 8px;
            bottom: -5px;
          }
        }
      }
    }
  }
}

// Input Groups
.pko-amount-currency-pair {
  &__flags {
    padding: 12px 0;
    app-input-number,
    app-select-flag {
      flex: 1;
    }
    .input-group__text {
      justify-content: center;
      min-width: 32px;
      padding: 0;
    }
  }

  &__amount {
    .input-group {
      app-input-number {
        flex: 1;
      }
    }
  }

  app-form-amount-currency {
    flex: 1;
  }

  app-select-flag {
    svg {
      height: 24px;
      width: 32px;
    }
  }
}

app-form-amount-currency,
app-form-amount-currency-pair {
  .input-group {
    app-input-number {
      flex: 1;

      input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      & + app-select-flag,
      & + app-form-currency-pair {
        .ng-select-container {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      & + app-form-currency-pair {
        flex: 0 0 55%;
      }
    }
  }
}

app-input-amount-left {
  .input-group {
    app-input-number {
      flex: 1;

      input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      & + app-select-flag {
        .ng-select-container {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
}

app-form-telephone {
  .input-group {
    app-select-flag,
    app-select {
      .ng-select-container {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      & + input {
        flex: 1;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

app-form-rate {
  .input-group {
    app-input-number {
      flex: 1;
    }
  }
}

app-form-tenor-date {
  .input-group {
    app-select {
      flex: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      .ng-select-container {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      & + app-datepicker {
        input {
          @include media-breakpoint-up(sm) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
    }
    app-datepicker {
      @include media-breakpoint-down(md) {
        flex: 1;
      }
    }
  }
}

app-form-expiration {
  & > app-form-control {
    .pko-grid__row {
      flex-grow: 1;
    }
  }
  app-datepicker {
    app-form-control {
      &.pko-datepicker {
        @include media-breakpoint-down(sm) {
          margin-bottom: 18px;
        }
      }
    }
  }
}

app-form-range-date,
app-form-range-number {
  & > app-form-control {
    .pko-grid__row {
      flex-grow: 1;
    }
  }
}

.input-group {
  &__text {
    align-items: center;
    display: flex;
    margin-bottom: auto;
    min-height: $input-height;
    padding: 0 16px;
  }

  app-form-control {
    margin-bottom: 0;
  }
}

//Form Controls
.form-control {
  $self: &;
  min-height: $input-height;

  &#{ $self }-sm {
    font-size: inherit;
    min-height: $input-height-sm;
  }

  &#{ $self }-lg {
    min-height: $input-height-lg;
  }
}

app-form-control {
  margin-bottom: 18px;

  .form-label {
    @extend %text-sm;
    margin-bottom: 6px;
    margin-top: 8px;
  }

  .form-hint {
    @extend .text-muted;
    @extend .text-sm;
    margin-top: 0.25rem;
  }
}

.pko-btn-group {
  width: 100%;
}

app-profile-tablist {
  .pko-list-group {
    width: 315.5px;
  }
}

app-input-radio,
app-simple-radio {
  padding: 8px 0 16px;

  .form-radio {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0;

    .form-radio-input {
      height: 20px;
      margin-left: 0;
      margin-right: 16px;
      margin-top: 0;
      width: 20px;
      flex-shrink: 0;
      background-color: $form-check-input-bg;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: $form-check-input-border;
      appearance: none;
      print-color-adjust: exact; // Keep themed appearance for print
      @include transition($form-check-transition);

      &[type="radio"] {
        border-radius: $form-check-radio-border-radius;
      }
      &:disabled,
      &:disabled ~ label {
        opacity: 0.5;
      }
      &:checked {
        border-color: $form-check-input-checked-border-color;

        &[type="radio"] {
          background-image: escape-svg($form-check-radio-checked-bg-image);
        }
      }
      &.ng-touched.ng-invalid {
        border-color: $danger;
      }
    }
  }

  &-label {
    @extend %text-md;
    display: inline-flex;
    align-items: center;
  }
}

app-input-check,
app-checkbox {
  padding: 8px 0 16px;

  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0;

    .form-check-input {
      height: 20px;
      margin-left: 0;
      margin-right: 16px;
      margin-top: 0;
      width: 20px;
      flex-shrink: 0;

      &.ng-touched.ng-invalid {
        border-color: $danger;
      }
    }

    &-label {
      @extend %text-md;
      display: inline-flex;
      align-items: center;
      white-space: pre-wrap;
    }

    &-description {
      @extend %text-sm;
      margin-top: 8px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@include media-breakpoint-down(sm) {
  app-input-check:not(.app-input-check--desktop),
  app-checkbox:not(.app-input-check--desktop) {
    padding: 16px 15px 24px;
    .form-check {
      justify-content: space-between;
      width: 100%;

      .form-check-input {
        background-color: $form-switch-color;
        background-image: escape-svg($form-switch-bg-image);
        background-position: left center;
        height: 24px;
        width: 40px;
        margin-left: $form-switch-padding-start;
        // Fixes background image position on smaller screens
        margin-right: 1px;
        border: 1px solid $form-switch-color;
        flex-shrink: 0;
        @include border-radius($form-switch-border-radius);
        @include transition($form-switch-transition);

        &:checked {
          background-color: $form-switch-checked-color;
          background-image: escape-svg($form-switch-checked-bg-image);
          background-position: $form-switch-checked-bg-position;
          border: 1px solid $form-switch-checked-color;
        }
      }

      &-label {
        order: -1;
      }

      &-container {
        order: -1;
      }
    }
  }
}

app-input-check.app-input-check--desktop.app-input-check--desktop--invalid,
app-checkbox.app-input-check--desktop.app-input-check--desktop--invalid {
  input {
    border-color: $danger;
  }

  label {
    color: $danger;
  }
}

app-transaction-control {
  form {
    app-input-check {
      min-height: 80px;

      .form-check {
        .form-check-input {
          @include media-breakpoint-up(sm) {
            margin-left: 19px;
            margin-right: 25px;
          }
        }
      }
    }
  }
}

app-alert {
  .alert {
    margin: 0.5rem 0;
  }
}

.pko-tabs {
  $self: &;
  border: none;
  box-shadow: inset 0 -1px 0 0 $gray-300;
  box-sizing: border-box;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  overflow-x: auto;
  padding: 8px 0 32px;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;

  @include media-breakpoint-down(lg) {
    box-shadow: inset 0 -1px 0 0 $gray-300;
    padding: 16px 0 24px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  & #{ $self }__item {
    flex: 0 0 auto;
    margin: 0 20px;
    padding-bottom: 3px;
    padding-top: 1px;
    position: relative;

    @include media-breakpoint-down(lg) {
      margin: 0 16px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  & #{ $self }__link {
    background: none;
    border: 0;
    padding: 0;
    margin-bottom: 0;
    color: $text-color;

    &:hover {
      color: $primary;
    }

    &::before {
      content: "";
      border-radius: 5px 0;
      bottom: -17px;
      display: block;
      height: 3px;
      width: 0;
      left: 50%;
      position: absolute;
      transform-origin: center;
      transform: skew(-25deg) translateX(-50%);
      transition: width 0.3s ease-in-out;
    }

    &.active {
      color: $primary;

      &::before {
        background-color: $primary;
        width: 100%;
      }
    }
  }
}

app-summary-quote,
app-form-multifx-sum,
app-summary-sum {
  app-labeled-text-inline {
    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
  hr {
    margin: 4px 0;

    & + app-labeled-text-inline {
      padding-top: 12px;
    }
  }
}

app-form-multifx-sum,
app-summary-sum {
  h4 {
    @extend %component-subtitle;
  }

  app-labeled-text-inline {
    app-flag {
      .pko-flag__label {
        @include pko-font-size(19);
        @include pko-line-height(19, 20);
        min-width: 33px;
      }
    }
  }
}

app-labeled-text-inline {
  padding-top: 8px;
  padding-bottom: 12px;

  & > label {
    @extend %text-sm;
    font-weight: 300;
  }

  app-flag {
    .pko-flag {
      width: 24px;
      height: 18px;
    }
  }

  h4 {
    @include pko-font-size(25);
    line-height: 1;
    margin-bottom: 0;
  }
}

.pko-form {
  $self: &;
  position: relative;

  // .pko-form--full modifier, form takes whole width even between sm -md breakpoint e.g. MultiFX Form
  @include media-breakpoint-between(sm, md) {
    &:not(#{ $self }--full) {
      & > .pko-grid__row {
        @include make-col(10);
        margin: 0 auto;
      }
    }
  }
  #{ $self }__title {
    @include pko-font-size(21);
    @include pko-line-height(21, 24);
    margin-top: 8px;
    margin-bottom: 24px;
  }

  &__section {
    &-title {
      @extend %text-sm;
      border-bottom: 1px solid $gray-300;
      color: $gray-700;
      margin-bottom: 24px;
      padding-bottom: 12px;
      padding-left: 16px;
      padding-top: 28px;
    }
  }
}

@mixin footer($size) {
  .pko-form-footer {
    $self: &;
    border-top: 1px solid $gray-300;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 8px;
    padding-bottom: 24px;
    padding-top: 16px;

    @include media-breakpoint-down($size) {
      flex-direction: column;
    }

    @include media-breakpoint-up($size) {
      padding: 32px 0;
    }

    &__countdown {
      max-width: 100%;
      width: 100%;

      @include media-breakpoint-down($size) {
        margin: 2px auto 16px;
      }

      @include media-breakpoint-between(sm, $size) {
        max-width: 285px;
      }

      @include media-breakpoint-up($size) {
        max-width: 366px;
      }

      & + .pko-form-footer__button-forward {
        @include media-breakpoint-down($size) {
          margin: 8px auto;
          width: 100%;
        }

        @include media-breakpoint-between(sm, $size) {
          max-width: 285px;
        }
      }
    }

    &__button {
      &-back {
        margin-right: auto;
        @include media-breakpoint-down($size) {
          display: none;
        }
      }

      &-forward {
        margin-left: auto;
      }
    }
  }

  .pko-countdown {
    $self: &;

    &__progressbar {
      margin-bottom: 16px;
      @include media-breakpoint-up($size) {
        transform: skew(-25deg);
        $progress-height: 8px;
      }

      .progress-bar {
        border-radius: $progress-border-radius;
        @include media-breakpoint-up($size) {
          transform: skew(-25deg);
        }
      }
    }

    &__text {
      text-align: center;
      @extend %text-sm;

      @include media-breakpoint-up($size) {
        @include pko-font-size(15);
        @include pko-line-height(15, 16);
      }
    }

    &__time {
      span {
        font-weight: 700;
      }
    }

    &__message {
      text-align: center;
    }
  }
}

@include footer(md);

.modal {
  @include footer(lg);
}

app-widget {
  width: 482px !important;
  box-sizing: content-box;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.pko-widget {
  $self: &;
  @extend .border;
  @extend .rounded;
  box-sizing: border-box;

  &#{ $self }--disabled {
    & #{ $self }__header {
      app-currency-pair {
        color: $gray-700;
        .pko-flag {
          opacity: 0.5;
        }
      }
    }
  }

  &__content {
    height: pko-rem-size(140);
  }

  .pko-ticking-rate {
    background-color: $gray-100;
    border-radius: 4px;
    border: 1px solid $gray-400;
    height: pko-rem-size(92);
    width: pko-rem-size(172);
    margin: 8px 0;

    &--disabled {
      border: none;
    }

    &__key {
      @extend %text-md;
    }

    &__value {
      @include pko-font-size(25);
      @include pko-line-height(25, 40);

      .icon--md {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px 12px 24px;

    &-group {
      display: flex;
      align-items: center;

      input {
        width: 154px;
        text-align: right;
      }

      app-action-menu {
        height: 40px;
        margin-left: 12px;
        padding: 6px;
        width: 40px;
      }
    }

    app-currency-pair {
      app-form-control {
        margin-bottom: 0;
        .input-group__text {
          padding: 0 8px;
        }
      }
    }
  }

  &__content {
    background-color: $gray-200;
    padding: 8px 24px;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
  }

  &__date {
    @extend %text-sm;
    font-weight: 300;
  }
}

.pko-ticking-rate {
  $self: &;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 12px 6px 12px;
  cursor: pointer;

  &--disabled {
    background-color: $gray-300;
    cursor: default;

    #{ $self }__key {
      color: $gray-700;
    }
  }

  &__key {
    @extend %text-sm;
    color: $primary;
    font-weight: 700;
    margin-bottom: 6px;
  }

  &__value {
    @include pko-font-size(17);
    @include pko-line-height(17, 24);
    display: flex;
    position: relative;
  }

  &__indicator {
    display: inline-flex;
    align-items: baseline;
  }
}

.pko-fast-deal {
  &__placeholder {
    align-items: center;
    border: 1px dashed $gray-400;
    border-radius: 4px;
    display: flex;
    height: 126px;
    justify-content: center;
    width: 345px;
  }
}

.pko-logout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;

  &__title {
    @extend %text-sm;
    color: $dark;
    margin-top: 4px;
  }
}

%profileButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;

  & + .tooltip {
    z-index: 10000;
  }

  &::before {
    @extend %text-sm;
    content: attr(data-initials);
    align-items: center;
    background-color: $gray-300;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 32px;
    justify-content: center;
    width: 32px;
    @include media-breakpoint-up(md) {
      @include pko-font-size(15);
      height: 40px;
      width: 40px;
    }
  }
}

.pko-profile {
  $self: &;
  @extend .d-flex;
  @extend .align-items-center;

  &__name {
    @extend %text-md;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-width: 160px;
    overflow: hidden;
    white-space: normal;
  }

  &::after {
    content: "";
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnCiAgdmVyc2lvbj0iMS4xIgogIGlkPSJXYXJzdHdhXzEiCiAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogIHg9IjBweCIKICB5PSIwcHgiCiAgdmlld0JveD0iMCAwIDE2IDI0IgogIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDI0OyIKICB4bWw6c3BhY2U9InByZXNlcnZlIgo+CiAgPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KICAgIC5zdDAgewogICAgICBjbGlwLXBhdGg6IHVybCgjU1ZHSURfMl8pOwogICAgICBmaWxsOiAjZmZmZmZmOwogICAgICBzdHJva2U6ICNkNWQ1ZDU7CiAgICAgIHN0cm9rZS1taXRlcmxpbWl0OiAxMDsKICAgIH0KICAgIC5zdDEgewogICAgICBmaWxsOiAjZmZmZmZmOwogICAgfQogIDwvc3R5bGU+CiAgPGcgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDggMTIpIj4KICAgIDxnPgogICAgICA8Zz4KICAgICAgICA8ZGVmcz48cmVjdCBpZD0iU1ZHSURfMV8iIHdpZHRoPSIxNCIgaGVpZ2h0PSIyNCIgLz48L2RlZnM+CiAgICAgICAgPGNsaXBQYXRoIGlkPSJTVkdJRF8yXyI+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8xXyIgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7IiAvPgogICAgICAgIDwvY2xpcFBhdGg+CiAgICAgICAgPHBhdGgKICAgICAgICAgIGNsYXNzPSJzdDAiCiAgICAgICAgICBkPSJNMTQuOSwyNEwxLjUsMTMuNGMtMS0wLjgtMS0yLDAtMi43TDE0LjksMC4xbDEzLjUsMTAuNmMxLDAuOCwxLDIsMCwyLjdMMTQuOSwyNHoiCiAgICAgICAgLz4KICAgICAgPC9nPgogICAgPC9nPgogICAgPHJlY3QgeD0iMTQiIHk9IjEuNSIgY2xhc3M9InN0MSIgd2lkdGg9IjIiIGhlaWdodD0iMjEuMiIgLz4KICA8L2c+Cjwvc3ZnPgo=)
      no-repeat;
    bottom: -14px;
    display: block;
    height: 24px;
    left: 24px;
    position: absolute;
    transform: scale(0);
    transition: transform 0.3s linear;
    width: 16px;
    z-index: 9999;

    @include media-breakpoint-up(lg) {
      left: 28px;
    }
  }

  &.show {
    &::after {
      transform: scale(1);
    }

    #{ $self }__button {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__name {
    display: none;
    @media (min-width: 1484px) {
      display: -webkit-box;
    }

    @extend %text-md;

    &.dropdown-toggle {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
  }
  &__code {
    @extend %text-sm;
    font-weight: 300;
  }

  #{ $self }__button {
    @extend %profileButton;

    &::after {
      content: "";
      background-position: 50%;
      background: url("data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6ICMwMDM1NzQ7CiAgICAgIH0KICAgICAgLmIgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPgogICAgPHBhdGgKICAgICAgY2xhc3M9ImEiCiAgICAgIGQ9Ik0xMiwxNC4zNzRoMEw2LjkzLDEwLjEyYTEuODgxLDEuODgxLDAsMCwxLDEuMTc5LS40OTQsMS43MjcsMS43MjcsMCwwLDEsMS4xMTEuNDU3TDEyLDEyLjQxNmwyLjc3OS0yLjMzM2ExLjczNSwxLjczNSwwLDAsMSwxLjExMi0uNDU3LDEuODY5LDEuODY5LDAsMCwxLDEuMTc5LjQ5M0wxMiwxNC4zNzRaIgogICAgLz4KICAgIDxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIC8+CiAgPC9nPgo8L3N2Zz4K");
      border: none;
      display: inline-block;
      height: 24px;
      transition-duration: 0.3s;
      width: 24px;
    }

    & ~ #{ $self }__name {
      padding-left: 8px;
    }
  }

  #{ $self }__menu {
    @include media-breakpoint-down(sm) {
      width: 100vw;
      left: 0;
      position: fixed;
      top: 48px;
    }

    @include media-breakpoint-down(md) {
      &.show {
        display: flex;
        flex-direction: column;
      }
    }

    margin-top: 0;
    border: 1px solid $gray-300;
    width: 290px;
    padding: 7px 0 0;
    z-index: 9999;

    @include media-breakpoint-down(md) {
      max-height: calc(100vh - 52px);
    }

    #{ $self }__list {
      @include media-breakpoint-down(md) {
        overflow-y: scroll;
      }

      &-item {
        .pko-profile__name {
          display: -webkit-box;
        }

        border-bottom: 1px solid $gray-300;
        padding: 8px 16px;
        @include media-breakpoint-up(sm) {
          padding: 8px 24px;
        }
        .pko-customer__button {
          padding: 0;
        }

        &--current {
          position: relative;
          padding: 12px 16px 16px 16px;
          @include media-breakpoint-up(sm) {
            padding: 16px 24px 12px 24px;
          }

          &::after {
            background: url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZyBpZD0icGtvX2ljb25fc3RhdHVzLWFrY2VwdF8yNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOCAtMjkwMCkiPgogICAgPHJlY3QKICAgICAgaWQ9IlJlY3RhbmdsZV8zOTgzIgogICAgICBkYXRhLW5hbWU9IlJlY3RhbmdsZSAzOTgzIgogICAgICB3aWR0aD0iMjQiCiAgICAgIGhlaWdodD0iMjQiCiAgICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExOCAyOTAwKSIKICAgICAgZmlsbD0iI2ZmZiIKICAgICAgb3BhY2l0eT0iMCIKICAgIC8+CiAgICA8cGF0aAogICAgICBpZD0idl82XyIKICAgICAgZD0iTTEwLjgsMTUuNCwyNiwxLjJhMy42MDYsMy42MDYsMCwwLDAtMi0uNmMtLjcsMC0xLjcuMi0zLjMsMS45aDBsLTguOCw4LjItLjguOC0uOC0uOC0zLTMuMWMtMS42LTEuNy0yLjUtMi0zLjMtMmEzLjQ1NiwzLjQ1NiwwLDAsMC0yLC43WiIKICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTE2IDI5MDMuOSkiCiAgICAgIGZpbGw9IiMyZTdkNDkiCiAgICAvPgogIDwvZz4KPC9zdmc+Cg==)
              no-repeat;
            content: "";
            height: 24px;
            margin: auto;
            position: absolute;
            right: 12px;
            top: 30px;
            width: 24px;
          }
        }
      }
    }

    // -----------------------------------------------------------------------------
    // Language switcher
    // -----------------------------------------------------------------------------

    #{ $self }__language {
      @extend %text-sm;
      background-color: $gray-200;
      display: flex;
      height: 56px;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      @include media-breakpoint-up(sm) {
        padding: 16px 12px 16px 36px;
      }

      @media (min-width: 1366px) {
        display: none;
      }

      app-language {
        app-select {
          .ng-select {
            .ng-select-container {
              background: none;
              border: none;
              text-transform: uppercase;
              @extend %text-sm;
            }

            .ng-option {
              text-transform: uppercase;
              @extend %text-sm;
            }

            .ng-arrow-wrapper {
              .ng-arrow {
                background-position: 50%;
                background: url("data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6ICMwMDM1NzQ7CiAgICAgIH0KICAgICAgLmIgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPgogICAgPHBhdGgKICAgICAgY2xhc3M9ImEiCiAgICAgIGQ9Ik0xMiwxNC4zNzRoMEw2LjkzLDEwLjEyYTEuODgxLDEuODgxLDAsMCwxLDEuMTc5LS40OTQsMS43MjcsMS43MjcsMCwwLDEsMS4xMTEuNDU3TDEyLDEyLjQxNmwyLjc3OS0yLjMzM2ExLjczNSwxLjczNSwwLDAsMSwxLjExMi0uNDU3LDEuODY5LDEuODY5LDAsMCwxLDEuMTc5LjQ5M0wxMiwxNC4zNzRaIgogICAgLz4KICAgIDxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIC8+CiAgPC9nPgo8L3N2Zz4K");
              }
            }
          }
        }
      }
    }
  }
}

.pko-customer {
  &--collapsed {
    max-height: 50vh;
    overflow: auto;
  }

  &__button {
    @extend %profileButton;
    width: 100%;
    border-radius: 0;
    transition: background 0.15s ease-in-out;
    &:hover {
      background: $gray-200;
    }

    &:before {
      margin-right: 8px;
    }
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

app-indicative-settings {
  app-indicative-settings-item {
    display: block;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
    app-input-check {
      .form-check {
        .form-check-input {
          @include media-breakpoint-up(sm) {
            margin-right: 32px;
          }
        }
      }
      app-currency-pair {
        app-form-control {
          margin-bottom: 0;
          .input-group__text {
            @include pko-font-size(17);
            @include pko-line-height(17, 20);
            color: $currency-pair-separator-color;
            padding: 0 8px;
            // Same width hack
            padding-right: 10px;
          }

          app-flag {
            .pko-flag__label {
              @include pko-font-size(17);
              @include pko-line-height(17, 20);
              min-width: 33px;
            }
          }
        }
      }
    }
  }
}

app-form-currency-pair {
  app-currency-pair {
    app-form-control {
      margin-bottom: 0;

      .input-group__text {
        min-height: 32px;
      }
    }
  }
}

.pko-decision {
  @extend .d-flex;
  @extend .position-relative;
  @extend .w-100;
}

@include media-breakpoint-up(sm) {
  app-form-deposit,
  app-form-deposit-tile,
  app-investment-deposit,
  app-investment-deposit-tile,
  app-transaction-decision[type="deposit"],
  app-transaction-decision[type="investment-deposit"] {
    .pko-form-footer__button-forward {
      width: auto;
    }
  }
}

.pko-multifx {
  &__transaction {
    &-amount {
      .input-group {
        app-input-number {
          flex: 1;
        }
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;

      padding: 10px 0 8px;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        margin-top: 0;
      }

      .btn {
        &:not(:last-child) {
          @include media-breakpoint-up(sm) {
            margin-right: 30px;
          }
        }
        min-width: 135px;
      }
    }
  }
}

app-form-order-type-rate-points {
  .pko-grid__row {
    & > app-select,
    & > app-input-number,
    & > app-form-rate {
      @extend .col-12;
      @extend .col-md-6;
    }
  }
}

app-form-side {
  app-form-button-group {
    margin-top: 16px;
    margin-bottom: 24px;
  }
}

// -----------------------------------------------------------------------------
// Customers
// -----------------------------------------------------------------------------

.pko-customer-group {
  $self: &;
  &:not(:first-of-type) {
    #{ $self }__header {
      border-top: none;
    }
  }
  &__header {
    @extend %text-sm;
    background: $gray-100;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    height: 32px;
    padding: 8px 16px;

    @include media-breakpoint-up(sm) {
      padding: 5px 8px;
      height: 24px;
      @include pko-font-size(11);
      @include pko-line-height(11, 14);
    }
  }
}

.pko-customer-list {
  $self: &;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  #{ $self }__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-300;
    padding: 12px 16px;
    height: 80px;
    @include transition($transition-base);

    &:hover {
      background-color: $gray-200;
      cursor: pointer;
    }

    &-initials {
      @extend %text-md;
      align-items: center;
      background-color: $gray-300;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 40px;
      justify-content: center;
      width: 40px;
      margin-right: 16px;
      font-weight: 700;
    }

    &-container {
      margin-right: auto;
    }

    &-name {
      @extend %text-md;
      margin-bottom: 4px;
    }

    &-code {
      @extend %text-sm;
      font-weight: 300;
    }
  }
}

// -----------------------------------------------------------------------------
// MultiFX Currency Pair
// -----------------------------------------------------------------------------
.pko-currency-pair {
  $self: &;

  &#{ $self }--multifx {
    #{ $self }__select {
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
}

.list-decimal {
  list-style-type: decimal;
}

.list-alpha {
  list-style-type: lower-alpha;
}
