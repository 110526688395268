// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------

/* You can add global styles to this file, and also import other style files */

/* Override bootstrap variables */
@import "variables";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// overriding ng-select variable from bootstrap variable.
// probably best move to bootstrap-overrides and select-overrides
$ng-select-height: $input-height;

/* Additional theming (default | material | ant.design). */
@import "~@ng-select/ng-select/scss/default.theme";

@import "fonts";
@import "root";
@import "mixins";
@import "functions";
@import "utils";

@import "preloader";
@import "typography";
@import "icons";
@import "flags";
@import "controls";
@import "tablist";
@import "select";
@import "modal";
@import "dialog";
@import "page";
@import "dashboard";
@import "nav";
@import "footer";
@import "grid";
@import "table";
@import "list-group";
@import "tooltip";
@import "dpw";
@import "graph";
@import "activation";
@import "purgatory";
@import "flows";
@import "selected-item";
@import "month-picker";

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
}

html,
body {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  min-width: 320px;
  background-color: $white;
  font-family: $font-family;
  display: flex;
  flex-direction: column;

  & > .pko-grid {
    flex: 1 auto;
  }
}
