.pko-dialog {
  $self: &;
  @extend %modal-base;
  @include media-breakpoint-down(sm) {
    // Fix for long content overflow
    overflow-y: auto;
  }

  &__close {
    @extend .btn;
    @extend .btn-link;
  }

  #{ $self }__icon {
    height: 96px;
    width: 96px;
    &--danger {
      @extend %svg-color-danger;
    }
  }

  #{ $self }__title {
    @extend %modal-title;
    &--danger {
      color: var(--bs-danger);
    }
  }

  #{ $self }__header {
    display: flex;
    flex-direction: column;

    #{ $self }__icon {
      padding-top: 24px;

      & + #{ $self }__title {
        padding-top: 16px;
        padding-bottom: 36px;
      }
    }

    #{ $self }__title {
      margin-bottom: 0;
      padding-top: 8px;

      @include media-breakpoint-up(sm) {
        padding-top: 27px;
      }
    }

    #{ $self }__close {
      @extend %modal-header-close;
    }
  }

  #{ $self }__container {
    @extend %modal-container;
    &--center {
      #{ $self }__header,
      #{ $self }__body {
        align-items: center;
        display: flex;
        flex-direction: column;

        p {
          text-align: center;
        }
      }

      #{ $self }__body {
        min-height: 72px;
      }
    }
  }

  #{ $self }__footer {
    @extend %modal-footer;

    @include media-breakpoint-down(sm) {
      // for sticky buttons on smaller screens
      position: sticky;
      bottom: 0;
      background: $white;
    }
  }
}

:not(.modal-content) {
  .pko-dialog {
    $self: &;
    #{ $self }__container__body {
      .pko-br {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }
  }
}
