// -----------------------------------------------------------------------------
// Mixins
// -----------------------------------------------------------------------------

@mixin pko-font-size($size) {
  font-size: pko-rem-size($size);
}

@mixin pko-line-height($font-size, $line-height) {
  line-height: pko-line-height($font-size, $line-height);
}

@mixin u-column-break($breakpoint: false) {
  @if ($breakpoint) {
    @include media-breakpoint-up($breakpoint) {
      break-after: column;
      /* Firefox hack */
      @supports not (break-after: column) {
        &::after {
          content: "";
          display: block;
          margin-bottom: 500vh;
        }
      }
    }
  } @else {
    break-after: column;
    /* Firefox hack */
    @supports not (break-after: column) {
      &::after {
        content: "";
        display: block;
        margin-bottom: 500vh;
      }
    }
  }
}
