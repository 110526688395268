// -----------------------------------------------------------------------------
// Flags
// -----------------------------------------------------------------------------

.pko-flag {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  font-size: 1rem;
  // if parent has display: flex
  flex-shrink: 0;

  &--left {
    margin-right: 8px;
  }

  &--right {
    @extend .order-last;
    margin-left: 8px;
  }

  &__label {
    @include pko-font-size(15);
    @include pko-line-height(15, 18);
    margin-top: 2px;
    white-space: nowrap;
  }
}

/* ==========================================
Single-colored icons can be modified like so:
.pko-flag-name {
  font-size: 32px;
  color: red;
}
========================================== */

div[class^="pko-flag-"],
div[class*=" pko-flag-"] {
  width: 1.3330078125em;
}
