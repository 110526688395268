%tagOutput {
  display: flex;
  flex-wrap: wrap;

  button {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
    padding-right: 12px !important;

    @include media-breakpoint-up(sm) {
      width: auto;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    span {
      margin-left: 0.5rem;
      svg {
        @extend %svg-colors-invert;
      }
    }
  }

  .pko-email,
  .pko-phone,
  app-application-attorney {
    width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      width: auto;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

%tagBtn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: $gray-200;
  border-radius: $border-radius;
  padding: 12px 8px 12px 16px;
  border: 1px solid $gray-400;
}

%tagCancelBtn {
  display: flex;
  margin-left: 8px;
  background: $gray-300;
  padding: 0.25rem;
  transition: background 0.2s linear;
  border-radius: 50%;

  &:hover {
    background: $gray-400;
  }
}

.pko-activation {
  $self: &;

  &__section {
    display: block;
    margin: 1rem 0 0;
    @include media-breakpoint-up(md) {
      margin: 2rem 0 0;
    }
  }

  &__subsection {
    display: block;
    margin: 0.5rem 0 0;
    @include media-breakpoint-up(md) {
      margin: 1rem 0 0;
    }

    & + #{ $self }__subsection {
      margin-top: 2rem;
    }
  }

  & #{ $self }__header {
    background-color: $white;
    margin-bottom: 0;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 6;

    &#{ $self }__header--with-progress {
      & + #{ $self }__body {
        padding-top: var(--header-height, 94px);
        @include media-breakpoint-up(md) {
          padding-top: var(--header-height, 218px);
        }
      }

      &.scrolled {
        & + #{ $self }__body {
          @include media-breakpoint-up(md) {
            padding-top: var(--header-height, 94px);
          }
        }
      }
    }

    & + #{ $self }__body {
      padding-top: var(--header-height, 36px);
      @include media-breakpoint-up(md) {
        padding-top: var(--header-height, 162px);
      }
    }

    @include media-breakpoint-up(md) {
      min-height: 184px;
      padding-top: 31px;
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }

    @each $breakpoint, $container-max-width in $container-max-widths {
      @if $breakpoint == md or $breakpoint == lg {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          $col10: ($container-max-width + $grid-gutter-width) * calc(10 / $grid-columns);
          $content-space-left: calc((100vw - $col10 + $grid-gutter-width) / 2);
          padding-left: $content-space-left;
          padding-right: $content-space-left;
        }
      }
    }

    &.scrolled {
      box-shadow: 0px 3px 6px rgba($dark, 0.08);

      @include media-breakpoint-up(md) {
        --#{$prefix}gutter-x: #{$container-padding-x};
        min-height: 80px;
        padding-top: 14px;
        padding-bottom: 12px;

        img {
          height: 56px;
        }
      }

      .pko-title {
        @include media-breakpoint-up(md) {
          @include pko-font-size(24);
          @include pko-line-height(24, 36);
          margin-top: 11px;
        }
      }

      img {
        height: 48px;
      }

      .pko-step-counter {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-content {
      display: inline-flex;
      align-items: center;
      @include media-breakpoint-up(md) {
        flex-direction: column;
      }
    }

    .pko-title {
      font-weight: 700;
      margin-top: 0;
      @extend %text-md;
      @include media-breakpoint-up(md) {
        margin-top: 31px;
        @include pko-font-size(48);
        @include pko-line-height(48, 56);
      }
    }

    img {
      padding: 8px 12px 8px 0;
      height: 52px;
      @include media-breakpoint-up(md) {
        height: 92px;
        padding: 0;
      }
      transition: all 200ms linear;
    }

    &-back {
      @include pko-font-size(17);
      @include pko-line-height(17, 20);
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      min-height: auto;
      padding: 0;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        height: 20px;
        width: auto;
        align-self: start;
      }
    }

    .pko-step-counter {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;

      @include media-breakpoint-up(md) {
        border: none;
        margin-top: 16px;
      }

      &__steps {
        margin: 8px 0 8px 12px;
        font-weight: 700;
      }

      &__step-name {
        color: $primary;
        font-weight: 400;
      }

      &__progress {
        width: 100%;
        height: 4px;

        @include media-breakpoint-up(md) {
          order: -1;
          height: pko-rem-size(40);
          width: pko-rem-size(40);
        }

        &-indicator {
          background: $primary;
          transition: width 200ms linear, background 200ms linear;
          width: var(--indicator-percentage);
          height: 100%;

          @include media-breakpoint-up(md) {
            background: radial-gradient(closest-side, $white 84%, transparent 80% 100%),
              conic-gradient($primary var(--indicator-percentage), $gray-100 0);
            border-radius: 50%;
            width: auto;
          }
        }
      }
    }
  }

  &__attorneys {
    display: block;
    &-output {
      @extend %tagOutput;
    }
  }

  &__attorney {
    @extend %tagBtn;
    min-height: 64px;

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-name {
      @extend %text-md;
    }

    &-pid {
      @extend %text-sm;
      color: $text-muted;
    }

    &-btn {
      @extend %tagCancelBtn;
      @extend %svg-colors-gray;
    }
  }

  &__footnotes {
    margin-top: 2rem;
  }

  &__footnote {
    @extend .text-muted;
    @extend .text-xs;
  }

  & #{ $self }__footer {
    border-top: 1px solid $gray-300;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1rem;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    @include media-breakpoint-up(sm) {
      padding: 32px 0;
    }

    &-button {
      &-back {
        margin-right: auto;

        @include media-breakpoint-down(sm) {
          & + #{ $self }__footer-button-forward {
            margin-top: 0.5rem;
          }
        }
      }

      &-forward {
        margin-left: auto;
      }
    }
  }

  &__waiting-icon {
    width: 115px;
    height: 152px;
    display: block;
    margin: 20px auto;
  }
}

.lei {
  text-transform: uppercase;
  letter-spacing: 6px;
  font-family: Consolas, monospace;
  font-size: 20px;
  font-weight: bold;
}

.pko-attorneys {
  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: var(--bs-gray-200);
  }

  .accordion-body {
    padding: 0;
  }

  .accordion-item {
    margin: 15px 0px;
  }
}

app-emails {
  .pko-emails__outcome {
    @extend %tagOutput;
  }

  .pko-email {
    @extend %tagBtn;
    @extend %text-md;

    &__cancel {
      @extend %tagCancelBtn;
      @extend %svg-colors-gray;
    }
  }
}

app-phones {
  .pko-phones {
    &__input {
      margin-bottom: 0;
      app-form-control {
        margin-bottom: 0;
      }
    }

    &__outcome {
      @extend %tagOutput;
    }
  }

  .pko-phone {
    @extend %tagBtn;
    @extend %text-md;

    &__cancel {
      @extend %tagCancelBtn;
      @extend %svg-colors-gray;
    }
  }
}

.pko-consent {
  $self: &;

  & app-form-control#{ $self }__container {
    display: flex !important;
    flex-direction: column;

    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    app-form-error {
      width: 100%;
    }
  }

  &__info-toggle {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    padding: 0 0 24px;
    @include media-breakpoint-up(sm) {
      padding: 8px 0 16px;
    }
    text-decoration: none;

    &::after {
      content: "";
      background-position: 50%;
      background: url(data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=)
        no-repeat;
      display: inline-block;
      height: 24px;
      transition-duration: 0.3s;
      width: 24px;
      margin-left: 0.5rem;
    }

    &[aria-expanded="true"]::after {
      transform: rotate(-180deg);
    }
  }
}

.pko-step-counter {
  $self: &;
  display: flex;
  flex-direction: row;
  align-items: center;
}
