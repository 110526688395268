.pko-tooltip {
  $self: &;
  display: flex;
  align-content: center;

  & > svg {
    &:hover {
      @extend %svg-colors-invert;
    }
  }

  &__backdrop {
    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zindex-tooltip-backdrop;
      width: 100vw;
      height: 100vh;
      background-color: $tooltip-backdrop-bg;
      opacity: $tooltip-backdrop-opacity;
    }
  }

  &__window {
    padding: 0;
    @include media-breakpoint-down(sm) {
      display: flex;
      position: fixed !important;
      flex-direction: column;
      justify-content: flex-end;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      transform: none !important;
    }

    #{ $self }__container {
      background-color: $white;
      box-shadow: 0px 4px 8px rgba($dark, 0.16);
      padding: 16px;
      position: relative;
      @include media-breakpoint-down(sm) {
        padding: 24px 16px;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        z-index: $zindex-tooltip;
      }

      #{ $self }__close {
        border: none;
        margin-left: auto;
        min-height: auto;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include media-breakpoint-down(sm) {
          height: 48px;
          width: 48px;
        }
      }
    }
    .tooltip-inner {
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        @include pko-font-size(15);
        @include pko-line-height(15, 20);
      }

      padding: 0;
      text-align: left;
      @extend %text-sm;
      font-family: $font-family;
    }
  }

  svg {
    width: 32px;
    height: 24px;
    cursor: pointer;
  }
}
