// -----------------------------------------------------------------------------
// Table
// -----------------------------------------------------------------------------
.pko-table {
  $self: &;

  #{$self}__header {
    &-row {
      border-bottom: 1px solid $gray-300;
    }

    &-col {
      &:first-of-type {
        padding-left: 16px;
      }
      @include pko-font-size(13);
      @include pko-line-height(13, 20);
      color: $text-muted;
      font-weight: 400;
      padding: 16px 8px;

      &--menu {
        @extend .text-end;
        padding: 16px;
      }
    }
  }

  #{$self}__body {
    #{$self}__row {
      height: 80px;
      border-bottom: 1px solid $gray-300;

      // Only for contracts flow summary
      &#{$self}__row--sm {
        height: 56px;
        vertical-align: middle;
      }

      &--empty {
        height: 128px;
        background-color: $gray-a100;

        #{$self}__row-cell {
          vertical-align: middle;

          #{$self}__empty-message {
            @extend %text-md;
            margin-bottom: 0;
          }
        }
      }

      &--group {
        height: 32px;
        @include media-breakpoint-up(sm) {
          height: 24px;
        }
      }

      &-dropdown {
        position: relative;
      }

      &-cell {
        &:first-of-type {
          padding-left: 16px;
        }
        @extend %text-md;
        padding: 16px 8px;

        &--menu {
          @extend .text-end;
          padding: 16px;
        }

        &--group {
          @extend %text-sm;
          background: $gray-100;
          border-top: 1px solid $gray-300;
          border-bottom: 1px solid $gray-300;
          padding: 8px 16px;

          @include media-breakpoint-up(sm) {
            padding: 5px 8px;
            @include pko-font-size(11);
            @include pko-line-height(11, 14);
          }
        }

        &--from-complex {
          padding-left: 24px !important;
        }

        &--separated {
          /* Separates this cell to the left with a border */
          padding-left: 24px;
          position: relative;

          &:before {
            content: "";
            border-left: 1px solid $gray-300;
            bottom: 0;
            display: block;
            height: calc(100% - 16px); //100% - padding-top - padding-bottom
            left: 8px;
            margin: auto 0;
            position: absolute;
            top: 0;
            @include media-breakpoint-up(sm) {
              height: calc(100% - 32px);
            }
          }
        }

        app-currency-pair {
          app-form-control {
            margin-bottom: 0;
            .input-group__text {
              padding: 0 8px;
            }
          }
        }

        .btn {
          & + .btn {
            &:not(:last-child) {
              @include media-breakpoint-up(sm) {
                margin-left: 24px;
              }
            }
          }
        }
      }

      &-details {
        display: none;
        @include media-breakpoint-up(sm) {
          display: table-row;
        }
        td {
          padding: 32px 30px;
          background-clip: padding-box;
        }
      }
    }
  }

  #{$self}__footer {
    &-row {
      height: 80px;
    }
  }
}

.pko-details {
  $self: &;

  display: block;

  &__content {
    @include media-breakpoint-up(sm) {
      column-gap: $grid-gutter-width;
      column-count: 2;
    }

    @include media-breakpoint-up(lg) {
      column-count: 3;
    }

    &--cancelled {
      @include media-breakpoint-up(lg) {
        column-count: 2;
      }
    }

    & > * {
      break-inside: avoid-column;
      /* For Firefox*/
      page-break-inside: avoid;
    }

    #{$self}__content-section {
      & > * {
        break-inside: avoid-column;
        /* For Firefox*/
        page-break-inside: avoid;
      }
    }

    &--subdetails {
      column-count: unset;
      .pko-table .pko-table__body .pko-table__row-details {
        font-size: 12px;
        td {
          padding: 16px 14px;
        }
      }
    }
  }

  & > #{$self}__footer,
  & > #{$self}__container > #{$self}__footer {
    @include media-breakpoint-up(sm) {
      margin-bottom: -30px;
    }
  }

  &__subtitle {
    @extend %component-subtitle;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  &__section {
    border-top: 1px solid $gray-300;
    display: block;

    &:last-child {
      #{$self}__footer {
        margin-bottom: -30px;
      }
    }
  }

  &__footer {
    column-span: all;
    display: flex;
    flex-direction: column;
    padding: 10px 16px 16px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      margin-top: 0;
      padding: 10px 0 16px;
    }

    .btn,
    .btn-tooltip-container {
      &:not(:last-child) {
        @include media-breakpoint-down(sm) {
          margin-bottom: 8px;
        }

        @include media-breakpoint-up(sm) {
          margin-right: 30px;
        }
      }
    }
  }
}

.pko-applications {
  tr {
    th:nth-child(4) {
      width: 356px;
    }

    td {
      .btn {
        min-width: 100px;
        min-height: 30px;
      }
    }
  }
}
