%tagBtn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: $gray-200;
  border-radius: 5px;
  padding: 12px 8px 12px 16px;
  border: 1px solid $gray-400;
}

%tagCancelBtn {
  display: flex;
  margin-left: 8px;
  background: $gray-300;
  padding: 0.25rem;
  transition: background 0.2s linear;
  border-radius: 5px;

  &:hover {
    background: $gray-400;
  }
}
.pko-selected-item {
  @extend %tagBtn;
  min-height: 64px;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__name {
    @extend %text-md;
  }

  &__btn {
    @extend %tagCancelBtn;
    @extend %svg-colors-gray;
  }
}
