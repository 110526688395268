.pko-preloader {
  $self: &;
  background-color: var(--bs-white);
  user-select: none;
  position: absolute;
  z-index: $zindex-preloader;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: inherit;

  &#{ $self }--transparent {
    background-color: $preloader-bg-transparent;
  }

  &#{ $self }--sticky {
    .pko-preloader__indicator {
      position: sticky;
      top: min(calc(50% - 16px), calc(100vh / 2 - 16px));
    }
  }

  &__indicator {
    width: 32px;
    height: 32px;

    margin: auto;
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);

    background-repeat: no-repeat;
    background-position: center center;
    background-image: none;

    border-radius: 50%;
    border: 4px solid var(--bs-primary);
    border-bottom-color: transparent;

    z-index: $zindex-preloader-indicator;

    animation: rotate 0.7s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal .pko-preloader {
  z-index: $zindex-preloader-modal;

  &__indicator {
    z-index: $zindex-preloader-indicator-modal;
  }
}
