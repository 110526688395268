.pko-grid {
  $self: &;
  display: flex;
  flex-direction: column;

  @include make-container();

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width;
    }
  }

  &__container {
    @include make-row($grid-gutter-width);
    justify-content: center;
    flex: 1 auto;
  }

  &__content {
    @include make-col-ready();
    @include make-col(12);

    padding-bottom: 40px;

    &:not(#{ $self }__content--without-nav) {
      padding-top: 40px;
      @include media-breakpoint-down(lg) {
        padding-top: 8px;
      }
    }

    &#{ $self }__content--full {
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
    }

    @include media-breakpoint-up(md) {
      @include make-col(10);
    }
  }

  &__row {
    @include make-row();

    & > * {
      @include make-col-ready();
    }

    @include make-grid-columns(10, $grid-gutter-width, $grid-breakpoints);

    &--12 {
      @include make-grid-columns(12, $grid-gutter-width-xs, $grid-breakpoints);
    }
  }
}
