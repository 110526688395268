// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------
// You can't extend classes inside mixin e.g.: using inside brekpoint mixin, if needed repeat code.

@import "functions";
@import "mixins";
@import "variables";
@import "utils";
@import "~bootstrap/scss/mixins";

%text-xs {
  @include pko-font-size(11);
  @include pko-line-height(11, 16);
}

%text-sm {
  @include pko-font-size(13);
  @include pko-line-height(13, 16);
}

%text-md {
  @include pko-font-size(15);
  @include pko-line-height(15, 20);
}

%text-lg {
  @include pko-font-size(21);
  @include pko-line-height(21, 32);
}

%text-xl {
  @include pko-font-size(33);
  @include pko-line-height(33, 48);
}

%text-xxl {
  @include pko-font-size(48);
  @include pko-line-height(48, 56);
}

// Modal title
%modal-title {
  @include pko-font-size(23);
  @include pko-line-height(23, 32);
}

%component-title {
  @include pko-font-size(25);
  @include pko-line-height(25, 32);
}

%component-subtitle {
  @include pko-font-size(19);
  @include pko-line-height(19, 24);
}

// Infotext styles
%text-infotext {
  @extend %text-sm;
  color: $text-muted;
  margin: 0;
  padding: 8px 0;
}

html {
  font-size: 100%;
}

body {
  @extend %text-md;
  font-family: $font-family;
  color: $text-color;
  font-weight: 400;
}

.pko-definition {
  display: block;
  &__term {
    @extend %text-sm;
    font-weight: lighter;
    display: inline-flex;
    align-items: center;
    margin-bottom: 2px;
    margin-top: 8px;

    & i {
      margin-left: 0.25rem;
    }

    &--tooltip {
      margin-top: 0;
    }
  }

  &__description {
    @extend %text-md;
    overflow-wrap: break-word;
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__remark {
    @extend %text-sm;
    color: $text-muted;
    margin-top: 4px;
    margin-bottom: 24px;
  }
}

.text {
  &-xs {
    @extend %text-xs;
  }

  &-sm {
    @extend %text-sm;
  }

  &-md {
    @extend %text-md;
  }

  &-lg {
    @extend %text-lg;
  }

  &-xl {
    @extend %text-xl;
  }
}

hr {
  color: $gray-300;
  opacity: 1;
  margin: 0.5rem 0;
}

h1 {
  &.pko-title {
    @extend %text-xl;
    margin-top: 10px;
    margin-bottom: 14px;

    & + p {
      &.pko-subtitle {
        padding-top: 0;
      }
    }
  }
}

p {
  &.pko-subtitle {
    @extend %text-infotext;
    display: inline-flex;
    align-items: center;
  }
}

.pko-section {
  &__title {
    @include pko-font-size(17);
    @include pko-line-height(17, 32);
    margin-bottom: 0;
  }

  &__subtitle {
    @extend %text-infotext;
    padding-top: 0;
  }
}

.pko-infotext {
  $self: &;
  @extend %text-infotext;

  &#{$self}--xs {
    @extend %text-xs;
    font-weight: 300;
  }
}

.pko-component {
  &__title {
    @extend %component-title;
    margin-top: 14px;
    margin-bottom: 17px;
  }

  &__subtitle {
    @extend %component-subtitle;
    margin-top: 21px;
    margin-bottom: 12px;
  }
}

.pko-title {
  $self: &;

  &#{$self}--1 {
    @extend %text-xl;
    @include media-breakpoint-down(md) {
      @include pko-font-size(25);
      @include pko-line-height(25, 32);
    }
  }

  &#{$self}--2 {
    @extend %component-title;
    @include media-breakpoint-down(md) {
      @include pko-font-size(23);
      @include pko-line-height(23, 32);
    }
  }

  &#{$self}--3 {
    @extend %modal-title;
    @include media-breakpoint-down(md) {
      @include pko-font-size(19);
      @include pko-line-height(19, 24);
    }
  }

  &#{$self}--4 {
    @extend %component-subtitle;
    @include media-breakpoint-down(md) {
      @include pko-font-size(15);
      @include pko-line-height(15, 20);
    }
  }
}
