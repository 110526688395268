// -----------------------------------------------------------------------------
// Purgatory
// -----------------------------------------------------------------------------
// place styles here if you're not sure if they are necessary or working in our favour

.btn {
  $self: &;
  @include pko-font-size(15);
  @include pko-line-height(15, 20);
  min-height: 40px;
  padding: 8px 24px;

  // Make default button lg on mobile
  @include media-breakpoint-down(sm) {
    @include pko-font-size(17);
    @include pko-line-height(17, 24);
    min-height: 48px;
    padding: 11px 24px;
  }

  &#{ $self }--label-align {
    margin-top: 30px;
  }

  // disabled button doesn't fire mouse events
  // for tooltip to work it must be set on the wrapper.
  .btn-tooltip-container {
    display: inline-block;
  }

  &#{ $self }-primary {
    border-radius: 0.25em 0;
    min-width: 150px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &#{ $self }-secondary {
    min-width: 150px;
  }

  &#{ $self }-secondary,
  &#{ $self }-outline-secondary {
    border-radius: 0.25em 0;
    color: $dark;
    border-color: $gray-400;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover,
    &:focus {
      background: $gray-200;
    }
  }

  &#{ $self }-link {
    text-decoration: none;
    color: $primary;
  }

  &#{ $self }-collapse {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    &#{ $self }-collapse--collapsed {
      &::after {
        transform: rotate(0);
      }
    }

    &::after {
      content: "";
      background-position: 50%;
      background: url("data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=")
        no-repeat;
      display: inline-block;
      height: 24px;
      transition-duration: 0.3s;
      width: 24px;
      transform: rotate(-180deg);
      margin-left: 8px;
    }
  }

  &#{ $self }-more {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    &::after {
      content: "";
      background-position: 50%;
      background: url("data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=")
        no-repeat;
      display: inline-block;
      height: 24px;
      transition-duration: 0.3s;
      width: 24px;
      margin-left: 8px;
    }

    &--collapsed {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &#{ $self }-add {
    display: inline-flex;
    align-items: center;
    padding: 0;
    &:hover {
      svg {
        background: $white;
        @extend %svg-colors;
      }
    }
    svg {
      transition: all 200ms ease-in-out;
      background: $primary;
      @extend %svg-colors-invert;
    }

    &--vertical {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      margin-top: 40px;
      svg {
        margin-bottom: 8px;
      }
    }

    &#{ $self }-add--invert {
      &:hover,
      &:focus {
        svg {
          background: $primary;
          @extend %svg-colors-invert;
        }
      }
      svg {
        background: $white;
        @extend %svg-colors;
      }
    }
  }

  &#{ $self }-fab {
    display: flex;
    position: sticky;
    margin-left: auto;
    bottom: 90px;

    @include media-breakpoint-up(sm) {
      bottom: 120px;
    }
  }

  &-row {
    padding: 0 1rem;
    margin: 0 -0.75rem;

    & #{ $self } {
      margin: 0.25rem 0.125rem;
      vertical-align: top;
    }
  }

  &-fixed {
    width: 180px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &#{ $self }-xs {
    @include pko-font-size(12);
    @include pko-line-height(12, 12);
    min-height: 24px;
    padding: 3px 6px;
  }

  &#{ $self }-sm {
    @include media-breakpoint-up(sm) {
      @include pko-font-size(15);
      @include pko-line-height(15, 20);
      min-height: 32px;
      padding: 5px 24px;
    }
  }

  &#{ $self }-lg {
    @include media-breakpoint-up(sm) {
      @include pko-font-size(17);
      @include pko-line-height(17, 24);
      min-height: 48px;
      padding: 11px 24px;
    }
  }
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &--sm {
    width: 16px !important;
    height: 16px !important;
  }

  &--md {
    width: 20px !important;
    height: 20px !important;
  }

  &--lg {
    width: 24px !important;
    height: 24px !important;
  }

  &--xl {
    width: 32px !important;
    height: 32px !important;
  }

  &--2xl {
    width: 40px !important;
    height: 40px !important;
  }

  &--3xl {
    width: 48px !important;
    height: 48px !important;
  }

  &--4xl {
    width: 96px !important;
    height: 96px !important;
  }
}

.pko {
  &-alert {
    display: flex;
    align-items: center;
    border: none;

    &-icon {
      border-radius: 50% !important;
      background: $white;
      line-height: 1;
      @extend .p-2;
      @extend .me-3;

      svg {
        @extend .icon--lg;
      }
    }

    &-content {
      @extend %text-sm;

      @include media-breakpoint-down(sm) {
        @include pko-font-size(11);
        @include pko-line-height(11, 16);
      }
    }
  }

  &-dashboard-pro {
    .pko {
      @include media-breakpoint-down(lg) {
        &-dashboard__area:first-child {
          .pko-grid__row {
            flex-wrap: wrap-reverse;
          }
        }
      }

      &-shortcuts {
        @include media-breakpoint-between(md, lg) {
          justify-content: space-around;
        }

        @include media-breakpoint-up(lg) {
          grid-template-columns: repeat(3, $shortcut-width);
        }
      }
    }
  }

  &-dashboard-simple {
    .pko-shortcuts {
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, $shortcut-width);
      }
    }
  }

  &-shortcuts {
    display: grid;
    grid-auto-columns: $shortcut-width;
    grid-column-gap: 16px;
    align-items: start;
    grid-auto-flow: column;
    overflow-x: auto;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(6, $shortcut-width);
      grid-column-gap: unset;
      grid-row-gap: 24px;
      grid-auto-flow: row;
      justify-content: space-between;
    }
  }

  &-shortcut {
    $self: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__icon {
      @extend %circled-icon;

      svg.icon-dashboard-switch {
        @extend .icon--xl;
      }
    }

    &__label {
      @extend .text-xs;
      font-weight: lighter;
      margin: 0 auto;
      max-width: 86px;
      padding-bottom: 4px;
      padding-top: 4px;
      text-align: center;
      overflow-wrap: break-word;
      cursor: pointer;
    }

    &__action {
      @extend .text-xs;
      @extend .link-primary;
      font-weight: lighter;
      margin-top: -4px;
      text-decoration: none;
      text-align: center;
    }

    &#{ $self }-dashboard-switch {
      #{ $self }__icon {
        background-color: $primary;

        &:hover {
          background-color: $white;

          svg {
            @extend %svg-colors;
          }
        }

        svg {
          @extend %svg-colors-invert;
        }
      }

      #{ $self }__action {
        @extend .d-none;
        @extend .d-md-flex;
      }
    }
  }
}

.pko-datepicker {
  $self: &;

  & #{ $self }__input {
    padding-right: $input-height;
    background-color: #fff;
    cursor: pointer;

    &.form-control-lg {
      padding-right: $input-height-lg;

      & + #{ $self }__button,
      & + ngb-datepicker + #{ $self }__button {
        min-height: $input-height-lg;

        svg {
          width: $input-height-lg;
          height: $input-height-lg;
        }
      }
    }

    &.form-control-sm {
      padding-right: $input-height-sm;

      & + #{ $self }__button,
      & + ngb-datepicker + #{ $self }__button {
        min-height: $input-height-sm;

        svg {
          width: $input-height-sm;
          height: $input-height-sm;
        }
      }
    }
  }

  .input-group {
    &:not(.has-validation) {
      > .dropdown-toggle:nth-last-child(n + 3) {
        @include border-end-radius($input-border-radius);
      }
    }
  }

  & #{ $self }__button {
    position: absolute;
    padding: 0;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    cursor: pointer;
    height: 100%;
    right: 0;
    z-index: 5;
    min-height: $input-height;

    &:focus {
      border-color: $input-focus-border-color;
    }

    svg {
      width: $input-height;
      height: $input-height;
    }

    &.dropdown-toggle {
      &::after {
        content: none;
      }
    }

    &:disabled {
      border: none;
    }
  }

  & #{ $self }__calendar {
    @include media-breakpoint-up(sm) {
      padding: 16px 28px;
    }

    @include media-breakpoint-down(sm) {
      padding: 16px 8px;
    }

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid $input-border-color;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &-button {
      width: 40px;
      height: 40px;
      padding: 0;
      border: none;
    }

    .ngb-dp-header {
      padding: 0;
    }

    &-month {
      [ngbDatepickerDayView] {
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: $primary;

        &:hover {
          color: $primary;
          background-color: $gray-200;
          border-color: $gray-200;
        }
      }

      .ngb-dp-day,
      .ngb-dp-weekday,
      .ngb-dp-week-number {
        width: 40px;
        height: 40px;
      }

      .ngb-dp-weekday,
      .ngb-dp-week-number {
        line-height: 40px;
        text-align: center;
        font-style: normal;
        text-transform: capitalize;
      }

      .ngb-dp-weekday,
      .ngb-dp-week-number {
        line-height: 40px;
        text-align: center;
        font-style: normal;
        text-transform: capitalize;
      }

      .ngb-dp-header {
        border-bottom: 0;
        border-radius: 0;
        padding-top: 0;
        background: none;
      }

      .ngb-dp-weekdays {
        border: none;
        background: none;
      }

      .ngb-dp-weekday {
        color: $gray-700;
      }

      .ngb-dp-month-name {
        font-size: inherit;
        height: auto;
        line-height: inherit;
        text-align: center;
        background: none;
        color: $dark;
      }
    }
  }

  &.show {
    app-form-error {
      display: none;
    }

    .form-control.ng-invalid.ng-touched {
      border-color: $input-border-color;
    }
  }
}

.pko-action {
  $self: &;

  & #{ $self }__button {
    background: $gray-200;
    padding: 2px;
    border: none;
    min-height: auto;

    @include media-breakpoint-down(sm) {
      padding: 4px;
    }

    svg {
      display: block;
    }

    &:hover {
      background-color: $primary;

      svg {
        @extend %svg-colors-invert;
      }
    }

    &::after {
      content: none;
    }
  }

  & #{ $self }__list {
    padding: 15px 7px 15px 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid $input-border-color;

    &-container {
      max-height: 280px;
      overflow-y: auto;
      padding-right: 10px;
      scrollbar-color: $primary $gray-400;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: $gray-400;
        border-left: 2px solid $white;
        border-right: 2px solid $white;
        border-radius: 1em;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-left: 1px solid $white;
        border-right: 1px solid $white;
        border-radius: 1em;

        &:hover {
          border-left: 1px solid $white;
          border-right: 1px solid $white;
          border-radius: 1em;
        }
      }
    }

    &-item {
      border-radius: $ng-select-border-radius;
      padding: 6px 16px;

      &:hover {
        background-color: $ng-select-marked;
        color: $ng-select-marked-text;
      }

      @include media-breakpoint-down(sm) {
        padding: 15px 8px;
      }

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}

.pko-btn-history-back {
  @extend .btn;
  @extend .btn-link;
  display: flex;
  align-items: center;
  padding: 8px 0px;
}

.pko-btn-group {
  $self: &;

  & #{ $self }__label {
    min-height: 32px;
    @include pko-font-size(13);
    @include pko-line-height(13, 16);
    padding: 7px 16px;

    @include media-breakpoint-down(sm) {
      min-height: 48px;
      @include pko-font-size(15);
      @include pko-line-height(15, 16);
      padding: 15px 16px;
    }

    @media (hover: hover) {
      &:hover {
        transition: background-color 0.5s;
        background: $gray-200;
        color: $primary;
      }
    }
  }
}

.pko-filter-status,
.pko-filter-pricing {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(sm) {
    justify-content: end;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row-reverse;
  }

  &__select {
    min-width: 210px;

    @include media-breakpoint-down(sm) {
      min-width: 100%;
    }
  }

  &__button {
    @extend .btn;
    @extend .btn-link;
    display: inline-flex;
    align-items: center;
    align-self: end;
    padding: 8px 0px;
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 18px;
      margin-right: 32px;
    }

    &-icon {
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
  }
}

$accordion-background: url(data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=);

.accordion-button::after {
  background: $accordion-background no-repeat;
}

.accordion-button:not(.collapsed)::after {
  background: $accordion-background no-repeat;
  transform: rotate(-180deg);
}

@include media-breakpoint-down(sm) {
  .accordion-button::after {
    background: none;
  }

  .accordion-button:not(.collapsed)::after {
    background: none;
  }
}

.pko-search {
  $self: &;
  background-color: $white;
  & #{ $self }__input {
    padding-left: $input-height;
    background-color: inherit;
    border-color: $input-border-color;
    cursor: pointer;

    &.form-control-lg {
      padding-left: $input-height-lg;

      & + #{ $self }__button,
      & + #{ $self }__button + #{ $self }__input {
        min-height: $input-height-lg;

        svg {
          width: $input-height-lg;
        }
      }
    }

    &.form-control-sm {
      padding-left: $input-height-sm;

      & + #{ $self }__button,
      & + #{ $self }__button + #{ $self }__input {
        min-height: $input-height-sm;

        svg {
          width: $input-height-sm;
        }
      }
    }
  }

  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
      .invalid-tooltip
    ):not(.invalid-feedback) {
    margin-left: 0;
    @include border-start-radius($input-border-radius);
  }

  & #{ $self }__button {
    position: absolute;
    border: none;
    padding: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    z-index: 5;
    min-height: $input-height;

    svg {
      width: $input-height;
    }
  }
}

.pko-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;

  @include media-breakpoint-up(sm) {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }

  .pko-title {
    margin-bottom: 0;
  }

  app-form-button-group {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.mobile-details {
  width: calc(100vw - 47px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 1055;
}

.list-overflow-hidden {
  @extend .overflow-hidden;
  height: 100vh !important;
}

.pko-filters {
  background-color: $gray-a100;
  display: block;
  padding: 16px;
  margin-bottom: 24px;

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: 8px;
    }

    .btn {
      &:not(:last-child) {
        @include media-breakpoint-up(sm) {
          margin-right: 30px;
        }
      }
    }
  }
}

.pko-open-balance-filters {
  @extend .d-flex;
  @extend .align-items-baseline;
  @extend .justify-content-between;
}

.pko-settlement-accordion__header {
  @extend %text-sm;
  background: $gray-100;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  padding: 8px 16px;

  @include media-breakpoint-up(sm) {
    padding: 5px 8px;
    @include pko-font-size(11);
    @include pko-line-height(11, 14);
  }
}

app-settlement-table {
  .pko-table__body {
    .pko-table__row.pko-table__row--settlements-group {
      height: auto;
      cursor: auto !important;
      @extend .bg-light;
    }

    [app-settlement-summary] {
      th:nth-child(n + 2):nth-last-child(n + 2) {
        width: calc(100% / 6);
      }
    }

    th:last-child {
      width: 60px;
    }
  }
}

.pko-settlement-accordion__sum {
  border-bottom: 1px solid $gray-300;
  border-top: 1px solid $gray-300;

  &__content {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    padding: 8px;

    &::after {
      content: "";
      background-position: 50%;
      background: url(data:image/svg+xml;base64,PHN2ZwogIGlkPSJwa29faWNvbl9jaGV2cm9uLWRvd25fbmF2XzI0IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMjQiCiAgaGVpZ2h0PSIyNCIKICB2aWV3Qm94PSIwIDAgMjQgMjQiCj4KICA8cGF0aAogICAgaWQ9ImJnIgogICAgZD0iTTAsMjRIMjRWMEgwWiIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMjQpIHJvdGF0ZSgtOTApIgogICAgZmlsbD0iI2ZmZiIKICAgIG9wYWNpdHk9IjAiCiAgLz4KICA8ZyBpZD0iR3JvdXBfMTQ0MzciIGRhdGEtbmFtZT0iR3JvdXAgMTQ0MzciPgogICAgPHBhdGgKICAgICAgaWQ9IlBhdGhfMTYyNDMiCiAgICAgIGRhdGEtbmFtZT0iUGF0aCAxNjI0MyIKICAgICAgZD0iTTEzLjU1NiwxNC42NDIsMTIsMTUuOTQ4bC0xLjU1Ni0xLjMwNkwzLjQ5NSw4LjgxMWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi0uMjQzTDEyLDEzLjMzN2w1LjY4My00Ljc2OWExLjk2MSwxLjk2MSwwLDAsMSwyLjgyMi4yNDNaIgogICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIgogICAgICBmaWxsPSIjMDAzNTc0IgogICAgLz4KICA8L2c+Cjwvc3ZnPgo=)
        no-repeat;
      border: none;
      display: inline-block;
      height: 24px;
      transition-duration: 0.3s;
      width: 24px;
    }

    &--selected {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  app-form-control {
    margin-bottom: 0;
  }

  th {
    vertical-align: middle;
  }
}

.pko-news pre {
  font-family: "pkobp";
}

%circled-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  @extend .rounded-circle;
  background-color: $white;
  height: 54px;
  width: 54px;
  margin: 4px 8px;

  &:hover {
    background-color: $primary;

    svg {
      margin-top: -4px;
      @extend %svg-colors-invert;
    }
  }

  svg {
    // Overrite for icons with round arrows
    height: 36px;
    width: 36px;
    transition: all 200ms ease-in-out;
  }
}

.pko-settings-banner {
  &__icon {
    @extend %circled-icon;
    flex: 1 0 auto;
    background-color: var(--bs-gray-200);
  }

  &__subtitle {
    @extend .px-2;
  }
}

app-history,
app-deposit-history {
  .pko-table tr:not(.pko-table__row-details):not(.pko-table__row--group) {
    cursor: pointer;
  }
}

@include media-breakpoint-down(sm) {
  app-transaction-decision {
    .pko-grid__row:last-of-type {
      padding-bottom: 130px;
    }

    &[type="order"] .pko-grid__row {
      padding-bottom: 50px;
    }

    &[type="multifx"] {
      padding-bottom: 130px;
      .pko-grid__row {
        padding-bottom: 0px;
      }
    }

    app-form-footer {
      position: fixed;
      bottom: 62px;
      left: 15px;
      right: 15px;
      z-index: 999;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.pko-table__strategy {
  background-color: var(--bs-gray-100);
  border-left: 1px solid var(--bs-gray-400);
  border-bottom: 1px solid var(--bs-gray-400);

  tr {
    border-left: 1px solid var(--bs-gray-400);

    &:last-child {
      td {
        border-bottom: 1px solid var(--bs-gray-400);
      }
    }
  }
}

app-detail-container {
  .pko-tabs {
    padding: 0px 0px 15px;

    .pko-tabs__link.active {
      background-color: unset;
      &:before {
        bottom: -3px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .pko-tabs {
      display: none;
    }
  }
}

app-main-filter {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  app-select .ng-dropdown-panel {
    margin: -32px;
  }
}

app-select.pko-filter-pricing__select .ng-dropdown-panel {
  margin: 16px;
}

th .pko-tooltip {
  display: inline;

  .tooltip-inner {
    left: -45px;
    position: relative;
  }
}

app-investment-deposit-details .u-column-break-lg {
  @supports not (break-after: column) {
    &::after {
      margin-bottom: 10vh;
    }
  }
}

app-form-exchange-forward,
app-form-exchange-swap,
app-form-exchange-close,
app-form-exchange-roll,
app-transaction-decision,
app-investment-deposit,
app-investment-deposit-tile {
  .alert-danger {
    background: var(--bs-danger);
    color: var(--bs-white);
    .pko-alert-icon {
      background: var(--bs-danger);
      .icon-critical {
        @extend %svg-colors-invert;
      }
    }
  }
}

app-dpw-list-section tr {
  th:first-child {
    width: 60px;
  }

  td:first-child {
    width: 60px;

    app-input-check .form-check {
      justify-content: flex-end;
      .form-check-input {
        margin-right: 0;
      }
    }
  }
}
