// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------

.pko-footer {
  $self: &;
  background: $white;
  border-top: 1px solid $gray-300;
  box-shadow: 0 -8px 0 $gray-200;
  display: flex;
  margin-top: auto;
  // Fix bottom menu
  padding-bottom: 64px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
    align-items: center;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  &#{ $self }--compact {
    padding-bottom: 0;
  }

  &__container {
    padding-top: 24px;
    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
    @include make-container();
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        max-width: $container-max-width;
      }
    }
  }

  &__row {
    @include make-row($grid-gutter-width);
    flex-flow: column-reverse;
    height: 256px;
    @include media-breakpoint-up(sm) {
      height: 64px;
    }
    @include media-breakpoint-up(md) {
      justify-content: space-between;
      align-items: center;
      flex-flow: row;
    }
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px 32px;
    flex-basis: 100%;
    &:first-child {
      padding-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      padding: 0;
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  #{ $self }__btn {
    display: inline-flex;
    align-items: center;
    min-height: auto;
    padding: 0;
    @include pko-font-size(15);
    @include pko-line-height(15, 24);
    color: $dark;
    @include media-breakpoint-down(sm) {
      padding-top: 8px;
    }

    @include media-breakpoint-up(sm) {
      &:not(:first-child) {
        margin-left: 40px;
      }
    }

    @include media-breakpoint-up(md) {
      &:first-child {
        margin-left: auto;
      }
    }

    .icon {
      margin-right: 8px;
    }
  }

  #{ $self }__link {
    @extend %text-sm;
    color: $text-muted;
    padding-bottom: 4px;
    padding-top: 4px;
    text-decoration: none;
    @include media-breakpoint-up(sm) {
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }
}
